import React, { useEffect, useState } from "react";
import { Navbar, Nav, DropdownButton, Dropdown } from "react-bootstrap";

import { Rate, Switch, Drawer, Card } from "antd";
import { mainLogoWhite, profile_Drop_Icon } from "../../../src/images";
import "./header.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  logOut,
  updateUser,
  userStatusChange,
  userNotificationStatusChange,
} from "../../actions/auth";
import {
  getHeaderSurveyRating,
  getAgents,
  updateConferenceScheduleStatus,
  sendChatEvent,
  getCustomerQueue,
  makeAgentEngaged,
} from "../../../src/actions/conversations";
import {
  getAgentsDetails,
  getAgentsList,
} from "../../actions/userManagementApis";
import { MdManageAccounts, MdLogout } from "react-icons/md";
import { GrUserAdmin, GrUserSettings, GrLogout } from "react-icons/gr";
import { IconContext } from "react-icons";
import { clearStorage } from "@createnextapp/async-local-storage";
import { UTILS } from "../../util/CommonUtilComponent.js";

import UserAvatar from "../../components/userAvatar/userAvatar.js";
import { borderColor, margin } from "@mui/system";
import { checklocalStorage } from "../../util/APIUtility";

const HeaderComponent = () => {
  let dispatch = useDispatch();

  const {
    isEngaged,
    agentInfo,
    conferenceData,
    engagedCustomer,
  } = useSelector((state) => ({ ...state }));

  const { conferenceStarted, appointment } = conferenceData;

  const [currentUserRating, setCurrentUserRating] = useState([]);
  const [agentData, setAgentData] = useState({});
  const [open, setOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchCurrentUserRating(history);
  }, []);

  useEffect(() => {
    fetchAgentDetails(history);
  }, []);

  const resetStatus = async () => {
    // send end call event
    var msgObj = {
      conversationId: JSON.parse(localStorage.getItem("engd_Cust")).convId,
      messagebody: `CALL_ENDED`,
      sentBy: "AGENT",
      type: "JITSI",
    };
    const updateChatEvent = sendChatEvent(msgObj, history);
    const updateScheduleStatusChange = updateScheduleStatus(false, history);
    await Promise.all([updateChatEvent, updateScheduleStatusChange]);
  };

  const onLoadJitsiCall = (IS_ENGAGED = false, STATUS = "online") => {
    dispatch({
      type: "IS_ENGAGED",
      payload: IS_ENGAGED,
    });

    // make agent STATUS to engaged
    agentInfo.status = STATUS;
    dispatch({
      type: "ADD_USERDATA",
      payload: agentInfo,
    });

    // adding engaged details to conference state
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        callEngagedCustomer: STATUS == "online" ? {} : engagedCustomer,
      },
    });

    // make agent status to engaged
    makeAgentEngaged(STATUS, history)
      .then((res) => {
        if (res && res.data && res.data.data) {
          console.log(res.data.data);

          //refresh the agent list
          fetchAgentList();
        }
      })
      .catch((err) => {
        UTILS.Notification("error", "Error while adding agent to chat");
        console.log(err);
      });
  };

  const updateScheduleStatus = (callStarted, history) => {
    let body = {
      ...appointment,
      callStarted: callStarted,
    };
    updateConferenceScheduleStatus(body, history)
      .then((res) => {
        console.log("res => ", res);
        onLoadJitsiCall(false, "online");
      })
      .catch((error) => {});
  };

  const signOut = () => {
    var data = JSON.stringify({
      authToken: checklocalStorage("authToken", null, history),
      refreshToken: checklocalStorage("refreshToken", null, history),
      userId: checklocalStorage("id", null, history),
    });
    var bearerToken = checklocalStorage("authToken", null, history);

    // make user offline after logout
    updateUserStatus("offline", history);
    signOutUser(data);
  };

  const updateAgentData = (updatedAgentData) => {
    updateUser(updatedAgentData, history)
      .then((res) => {})
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const updateUserStatus = (status, history) => {
    try {
      userStatusChange(status, agentData.id, history)
        .then((res) => {
          if (UTILS.HandleAPIResponse(res.data)) {
            // UTILS.Notification("success", res.data.data);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.log("Error: ", error);
    }

    // refresh agent list
    setTimeout(() => {
      fetchAgentList(history);
    }, 2000);
  };

  const updateUserNotificationStatus = (status, history, updatedAgentData) => {
    userNotificationStatusChange(status, agentData.id, history)
      .then((res) => {
        if (UTILS.HandleAPIResponse(res.data)) {
          setAgentData(updatedAgentData);
          // make agent engaged
          dispatch({
            type: "ADD_USERDATA",
            payload: updatedAgentData,
          });
          UTILS.Notification("success", res.data.data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const signOutUser = (data) => {
    try {
      console.log(
        "header====================================================="
      );
      logOut(data)
        .then((res) => {
          dispatch({
            type: "GOTO_ENGAGED",
            payload: {},
          });
          dispatch({
            type: "DISPLAY_CHAT",
            payload: false,
          });
          dispatch({
            type: "LOGOUT",
            payload: null,
          });
          dispatch({
            type: "ADD_CUSTOMERS",
            payload: [],
          });
          dispatch({
            type: "ADD_USERDATA",
            payload: { firstName: "", lastName: "" },
          });
          dispatch({
            type: "AGENT_QUEUE_LIST",
            payload: [],
          });

          // localStorage.removeItem("user");
          localStorage.removeItem("auth");
          localStorage.removeItem("userName");
          localStorage.removeItem("userKey");
          localStorage.removeItem("id");
          localStorage.removeItem("authToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("customerId");
          localStorage.removeItem("jitsiMeetId");
          localStorage.removeItem("callStatsUserName");
          localStorage.removeItem("currCusList");
          localStorage.removeItem("currMsgList");
          localStorage.removeItem("role");
          localStorage.removeItem("convID");
          localStorage.removeItem("customerName");
          localStorage.removeItem("company");
          localStorage.removeItem("companyConfig");
          localStorage.removeItem("sideBarSelection");

          //Reload page
          // if (window?.location) window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrentUserRating = (history) => {
    getHeaderSurveyRating(history)
      .then((res) => {
        if (res && res.data && res.data.data) {
          const data = res.data.data;
          setCurrentUserRating(data);
        }
      })
      .catch((error) => {
        console.error("These was an error", error);
      });
  };

  const fetchAgentDetails = (history) => {
    try {
      // delay of 2sec for getting updated data
      // setTimeout(() => {
      getAgentsDetails(history).then((res) => {
        if (res) {
          const data = res.data?.data[0] ? res.data?.data[0] : [];
          if (data.status === "engaged") {
            resetStatus();
          }
          setAgentData(data);
          dispatch({
            type: "ADD_USERDATA",
            payload: data,
          });
          console.log(data);
          if (data.active == false) {
            history.push("/logout");
          }
        }
      });

      // }, 2000);
    } catch (error) {
      console.error("ERROR: ", error);
    }
  };

  const fetchAgentList = (history) => {
    getAgentsList(history)
      .then((res) => {
        const data = res.data.data;
        if (null != data && data.length >= 0) {
          dispatch({
            type: "GET_AGENTSLIST",
            payload: data,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <header>
      <Navbar expand="sm" className="top-header">
        <div className="top-header__left">
          <div className="logo">
            <img src={mainLogoWhite} alt="Logo" />
          </div>
        </div>
        <div className="top-header__right">
          <div className="status-switch">
            {agentInfo && agentInfo.status && (
              <div className="status-item">
                {agentInfo.status == "online"
                  ? "Online"
                  : agentInfo.status == "engaged"
                  ? "Engaged"
                  : "Offline"}
              </div>
            )}
            {agentInfo && agentInfo.status && (
              <div className="status-item">
                <Switch
                  disabled={agentInfo.status == "engaged" ? true : false}
                  checked={
                    agentInfo.status == "online" ||
                    agentInfo.status == "engaged"
                  }
                  // defaultChecked={true}
                  size="small"
                  onChange={(checked) => {
                    let updatedAgentData = {
                      ...agentInfo,
                      status: checked ? "online" : "offline",
                    };

                    getCustomerQueue(history)
                      .then((res) => {
                        if (res && res.data && res.data.data) {
                          const data = res.data.data;
                          if (null != data) {
                            let newQueue = [];

                            data.forEach((el) => newQueue.push(el));
                            dispatch({
                              type: "ADD_CUSTOMERS",
                              payload: newQueue,
                            });
                            setAgentData(updatedAgentData);
                            // make agent engaged
                            dispatch({
                              type: "ADD_USERDATA",
                              payload: updatedAgentData,
                            });
                            updateUserStatus(updatedAgentData.status, history);
                          }
                        }
                      })
                      .catch((error) => {
                        console.error("There was an error!", error);
                      });
                  }}
                />
              </div>
            )}
          </div>
          <Navbar.Toggle
            aria-controls="navbar-collapse"
            onClick={handleToggle}
          />
          <Navbar.Collapse id="navbar-collapse" style={{ display: "none" }}>
            <Nav className="ml-auto">
              <Navbar.Text className="logged-agentname">
                Welcome,{" "}
                <span>
                  {agentInfo.lastName === "" ||
                  agentInfo.lastName === null ||
                  agentInfo.lastName === undefined
                    ? agentInfo.firstName
                    : `${agentInfo.firstName} ${agentInfo.lastName}`}
                </span>
                <div className="rating-box">
                  <div className="rating-value">
                    {Math.round(currentUserRating.rating) || 0}
                    <span> ({currentUserRating.count || 0})</span>
                  </div>
                  <Rate allowHalf disabled value={currentUserRating.rating} />
                </div>
              </Navbar.Text>
              {/* <DropdownButton
                menuAlign="right"
                title={
                  <div className="">
                    <img
                      className="thumbnail-image"
                      src={unitedStatesIcon}
                      alt="Language Icon"
                    />
                  </div>
                }
                id="language-dropdown-menu" 
              >
                <Dropdown.Item eventKey="1">English</Dropdown.Item>
                <Dropdown.Item eventKey="2">German</Dropdown.Item>
                <Dropdown.Item eventKey="3">French</Dropdown.Item>
              </DropdownButton> */}
              <DropdownButton
                autoClose={false}
                menuAlign="right"
                onClick={() => {
                  fetchAgentDetails(history);
                }}
                title={
                  <UserAvatar
                    url={
                      agentInfo.profileImage !== null
                        ? agentInfo.profileImage
                        : profile_Drop_Icon
                    }
                    status={agentInfo.status || "offline"}
                  />
                }
                id="profile-dropdown-menu"
              >
                {/* <Dropdown className="available-status">
                  <p className="available-status__text">Available</p>{" "}
                  <div className="available-status__switch">
                    <BootstrapSwitchButton
                      checked={true}
                      size="sm"
                      onChange={(checked) => {
                        changeStatus(checked);
                      }}
                    />
                  </div>
                </Dropdown> */}
                {/* <Dropdown.Item eventKey="2">Set Status Message</Dropdown.Item> */}
                <div style={{ margin: "5px auto" }}>
                  <Dropdown.Item
                    eventKey="10"
                    disabled
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className="user-company">
                      {" "}
                      {checklocalStorage("company", null, history)}
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="9"
                    disabled
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className="user-role">
                      {" "}
                      {checklocalStorage("role", null, history) === "superadmin"
                        ? "Super Admin"
                        : checklocalStorage("role", null, history)}
                    </span>
                  </Dropdown.Item>
                </div>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="nav-menu-options"
                  eventKey="3"
                  disabled={agentInfo.status == "engaged" ? true : false}
                  as={Link}
                  to="/ProfileSettings"
                  onClick={() => {
                    dispatch({
                      type: "SIDEBAR_SELECT",
                      payload: null,
                    });
                  }}
                >
                  Manage Profile
                  <IconContext.Provider value={{ size: "19px" }}>
                    <div>
                      <GrUserSettings />
                    </div>
                  </IconContext.Provider>
                </Dropdown.Item>
                {/* <Dropdown.Item eventKey="4" 
                  disabled={isEngaged ? true: false} 
                  as={Link} 
                  to="/CannedResponses">Canned Responses</Dropdown.Item> */}
                {/* <Dropdown.Item eventKey="5">Settings</Dropdown.Item> */}
                {checklocalStorage("role", null, history) === "superadmin" ||
                checklocalStorage("role", null, history) === "admin" ? (
                  <Dropdown.Item
                    className="nav-menu-options"
                    eventKey="6"
                    disabled={agentInfo.status == "engaged" ? true : false}
                    as={Link}
                    to="/AdminSettings"
                    onClick={() => {
                      dispatch({
                        type: "SIDEBAR_SELECT",
                        payload: null,
                      });
                    }}
                  >
                    Administration
                    <IconContext.Provider value={{ size: "20px" }}>
                      <div>
                        <GrUserAdmin />
                      </div>
                    </IconContext.Provider>
                  </Dropdown.Item>
                ) : (
                  ""
                )}

                {/* <Dropdown.Item
                  className="nav-menu-options"
                  eventKey="11"
                  disabled={isEngaged ? true : false}
                >
                  {agentInfo.status == "online"
                    ? "Online"
                    : agentInfo.status == "engaged"
                    ? "Engaged"
                    : "Offline"}
                  <Switch
                    checked={
                      agentInfo.status == "online" ||
                      agentInfo.status == "engaged"
                    }
                    // defaultChecked={true}
                    size="small"
                    onChange={(checked) => {
                      let updatedAgentData = {
                        ...agentData,
                        status: checked ? "online" : "offline",
                      };
                      setAgentData(updatedAgentData);
                      // make agent engaged
                      dispatch({
                        type: "ADD_USERDATA",
                        payload: updatedAgentData,
                      });
                      updateUserStatus(updatedAgentData.status);
                    }}
                  />
                </Dropdown.Item> */}

                <Dropdown.Item
                  className="nav-menu-options"
                  eventKey="12"
                  disabled={agentInfo.status == "engaged" ? true : false}
                >
                  {"Notification On/Off"}
                  <Switch
                    checked={agentInfo.notification}
                    size="small"
                    onChange={(checked) => {
                      let updatedAgentData = {
                        ...agentInfo,
                        notification: checked,
                      };

                      updateUserNotificationStatus(
                        updatedAgentData.notification,
                        history,
                        updatedAgentData
                      );
                    }}
                  />
                </Dropdown.Item>

                {/* <Dropdown.Item eventKey="7">Usage Status</Dropdown.Item> */}
                <Dropdown.Item
                  eventKey="8"
                  disabled={isEngaged ? true : false}
                  onClick={signOut}
                  as={Link}
                  to="/logout"
                  className=" nav-menu-options log-out"
                >
                  Sign out
                  <IconContext.Provider value={{ size: "20px" }}>
                    <div>
                      <GrLogout />
                    </div>
                  </IconContext.Provider>
                </Dropdown.Item>
              </DropdownButton>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Drawer
        className="toggleDrawer"
        placement="right"
        onClose={closeDrawer}
        visible={open}
        width="70%"
        getContainer={false}
        maskClosable={true}
        closable={false}
      >
        <Nav>
          <Navbar.Text className="logged-agentname">
            Welcome,{" "}
            <span>
              {agentInfo.lastName === "" ||
              agentInfo.lastName === null ||
              agentInfo.lastName === undefined
                ? agentInfo.firstName
                : `${agentInfo.firstName} ${agentInfo.lastName}`}
            </span>
            <div className="rating-box">
              <div className="rating-value">
                {Math.round(currentUserRating.rating)}
                <span> ({currentUserRating.count})</span>
              </div>
              <Rate allowHalf disabled value={currentUserRating.rating} />
            </div>
          </Navbar.Text>
          <Card
            className="mobileuser"
            hoverable
            onClick={() => {
              fetchAgentDetails(history);
            }}
            style={{
              width: 200,
              fontSize: "14px",
              size: "medium",
              border: "2px solid #c64339",
              borderRadius: "4px",
            }}
            cover={
              <UserAvatar
                url={
                  agentInfo.profileImage !== null
                    ? agentInfo.profileImage
                    : profile_Drop_Icon
                }
                status={agentInfo.status || "offline"}
                style={{ marginLeft: "39%", paddingTop: "8px", height: "40px" }}
              />
            }
            // id="profile-dropdown-menu"
          >
            <div style={{ paddingBottom: "5px auto" }}>
              <div
                disabled
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="user-company">
                  {" "}
                  {checklocalStorage("company", null, history)}
                </span>
              </div>
              <div
                disabled
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="user-role">
                  {" "}
                  {checklocalStorage("role", null, history) === "superadmin"
                    ? "Super Admin"
                    : checklocalStorage("role", null, history)}
                </span>
              </div>
            </div>

            <div
              className="nav-menu-options-ontoggle"
              eventKey="3"
              disabled={isEngaged ? true : false}
              as={Link}
              // to="/ProfileSettings"
              onClick={() => {
                history.push("/ProfileSettings");
                setOpen(false);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              Manage Profile
              <IconContext.Provider value={{ size: "20px" }}>
                <div>
                  <GrUserSettings />
                </div>
              </IconContext.Provider>
            </div>
            {checklocalStorage("role", null, history) === "superadmin" ||
            checklocalStorage("role", null, history) === "admin" ? (
              <div
                className="nav-menu-options-ontoggle"
                eventKey="6"
                disabled={isEngaged ? true : false}
                as={Link}
                // to="/AdminSettings"
                onClick={() => {
                  history.push("/AdminSettings");
                  setOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "4px",
                }}
              >
                Administration
                <IconContext.Provider value={{ size: "20px" }}>
                  <div>
                    <GrUserAdmin />
                  </div>
                </IconContext.Provider>
              </div>
            ) : (
              ""
            )}

            <div
              className="nav-menu-options"
              eventKey="12"
              disabled={isEngaged ? true : false}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              {"Notification On/Off"}
              <Switch
                checked={agentInfo.notification}
                size="small"
                onChange={(checked) => {
                  let updatedAgentData = {
                    ...agentData,
                    notification: checked,
                  };
                  updateUserNotificationStatus(
                    updatedAgentData.notification,
                    history,
                    updatedAgentData
                  );
                }}
              />
            </div>

            <div
              eventKey="8"
              disabled={isEngaged ? true : false}
              // onClick={signOut}
              as={Link}
              // to="/logout"
              onClick={() => {
                signOut();
                history.push("/logout");
                setOpen(false);
              }}
              className=" nav-menu-options log-out"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              Sign out
              <IconContext.Provider value={{ size: "20px" }}>
                <div>
                  <GrLogout />
                </div>
              </IconContext.Provider>
            </div>
          </Card>
        </Nav>
      </Drawer>
    </header>
  );
};

export default HeaderComponent;
