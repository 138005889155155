let selection = localStorage.getItem("sideBarSelection");
const initialState = selection ? selection : "2";

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIDEBAR_SELECT":
      return action.payload;
    default:
      return state;
  }
};
