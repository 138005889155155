import axios from "axios";
import {
  AGENT_SERVICE,
  AUTHENTICATION_SERVICE,
} from "../Enviornment/enviornment.js";
import URL_Properties from "../Enviornment/url.js";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { apiRequest, checklocalStorage } from "../util/APIUtility.js";

async function token(history) {
  var data = "";
  data = await checklocalStorage("authToken",null,history);
  console.log("authtoken:",data);
  return data;
}

async function getCompany(history) {
  var data = "";
  data = await checklocalStorage("companyConfig","id",history);
  console.log("companyid:",data);
  return data;
}

async function userId(history) {
  var data = "";
  data = await checklocalStorage("id",null,history);
  console.log("userid:",data);
  return data;
}

export const getDepartmentList = async (history) => {
  var urls = `${AGENT_SERVICE}${
    URL_Properties.FETCH_DEPTLIST
  }${await getCompany(history)}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getTeamsList = async (history) => {
  var urls = `${AGENT_SERVICE}${
    URL_Properties.FETCH_TEAMLIST
  }${await getCompany(history)}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getAgentsListAccordingToPagination = async (pageNumber, size,searchText,history) => {
  var urls = `${AUTHENTICATION_SERVICE}Users/company/${await getCompany(history)}`;
  let data = JSON.stringify({
    pageNumber: pageNumber,
    size: size,
    searchText: searchText,
  });
  var config = {
    method: "post",
    url: urls,
    headers: {
      token: await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: { pageNumber: pageNumber, size: size ,searchText: searchText},
  };
  // return await axios(config);
  return await apiRequest("POST", urls, data, null, null, history);

};

export const getAgentsList = async (pageNumber, size,history) => {
  var urls = `${AUTHENTICATION_SERVICE}Users/company/${await getCompany(history)}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getMeetingAttendees = async (history) => {
  var urls = `${AGENT_SERVICE}api/v1/Meeting/Attendees/${await userId(history)}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getAgentsDetails = async (history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/Users`;
  var data = [await userId(history)];
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: [await userId(history)],
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const setAgentsProfilePic = async (file, history) => {
  var urls = `${AGENT_SERVICE}api/v1/profile`;

  const data = new FormData();
  data.append("file", file);
  data.append("userId", await userId(history));

  console.log(data);
  var config = {
    method: "post",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const addTeam = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.ADD_TEAM}${
    data.name
  }/company/${await getCompany(history)}`;
  var data = data.agent;
  var config = {
    method: "POST",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data.agent,
  };
  return await apiRequest(
    "POST",
    urls,
    JSON.stringify(data),
    null,
    null,
    history
  );
  // return await axios(config);
};

export const addAgent = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.ADD_USER}`;
  var config = {
    method: "POST",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest(
    "POST",
    urls,
    JSON.stringify(data),
    null,
    null,
    history
  );
  // return await axios(config);
};

export const addDepartment = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.ADD_DEPTMENT}${
    data.name
  }/company/${await getCompany(history)}`;
  var config = {
    method: "POST",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data.team,
  };
  return await apiRequest(
    "POST",
    urls,
    JSON.stringify(data.team),
    null,
    null,
    history
  );
  // return await axios(config);
};
export const deleteDepartment = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.DELETE_DEPT}${data}`;

  var config = {
    method: "DELETE",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("DELETE", urls, null, null, null, history);
  // return await axios(config);
};
export const deleteTeam = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.DELETE_TEAM}team=${data}`;

  var config = {
    method: "DELETE",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("DELETE", urls, null, null, null, history);
  // return await axios(config);
};
export const deleteAgent = async (id, checked,history) => {
  //api/v1/update/user/{userid}/active/{boolean}
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.DELETE_AGENT}${id}/active/${checked}`;

  var config = {
    method: "PUT",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };

  // return await axios(config);
  return await apiRequest("PUT", urls, null, null, null, history);

};

// export const modifyDepartment= async (action,data) => {

//   var urls = `${AGENT_SERVICE}${URL_Properties.MODIFY_DEPT}${action}/Department/${data.id}`;
//   var config = {
//     method: 'PUT',
//     url: urls,
//     headers: {
//         // 'token': await token(),
//         'accept': '*/*',
//         'Content-Type': 'application/json'
//     },
// 	 data : data.team
//   };

//   return await axios(config)
// }

// export const renameDepartment= async (data) => {

//   var urls = `${AGENT_SERVICE}api/v1/Department/${data.id}/name/${data.name}`;
//   var config = {
//     method: 'PUT',
//     url: urls,
//     headers: {
//         'token': await token(),
//         'accept': '*/*',
//         'Content-Type': 'application/json'
//     }
//   };

//   return await axios(config)
// }

// export const renameTeam= async (data) => {

//   var urls = `${AGENT_SERVICE}api/v1/Team/${data.id}/name/${data.name}`;
//   var config = {
//     method: 'PUT',
//     url: urls,
//     headers: {
//         'token': await token(),
//         'accept': '*/*',
//         'Content-Type': 'application/json'
//     }
//   };

//   return await axios(config)
// }

export const modifyDepartment = async (data,history) => {
  var urls = `${AGENT_SERVICE}api/v1/Department`;

  var config = {
    method: "PUT",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };

  // return await axios(config);
  return await apiRequest("PUT", urls, data, null, null, history);
};

export const modifyTeam = async (data,history) => {
  var urls = `${AGENT_SERVICE}api/v1/Team`;

  var config = {
    method: "PUT",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };

  // return await axios(config);
  return await apiRequest("PUT", urls, data, null, null, history);
};

// export const modifyTeam= async (action,data) => {
// 	//Agent/Action/{action}/Team/{teamId}
//   var urls = `${AGENT_SERVICE}${URL_Properties.MODIFY_TEAM}${action}/Team/${data.id}`;

//   var config = {
//     method: 'PUT',
//     url: urls,
//     headers: {
//         // 'token': await token(),
//         'accept': '*/*',
//         'Content-Type': 'application/json'
//     },
// 	 data : data.agent
//   };

//   return await axios(config)
// }

export const modifyAgent = async (data,history) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.MODIFY_AGENT}${data.id}`;

  var config = {
    method: "PUT",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };

  // return await axios(config);
  return await apiRequest("PUT", urls, data, null, null, history);
};
