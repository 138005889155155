import React from "react";
import { DialogueViewLogo } from "../../images";
import "./Header.scss";

const Header = () => {
  return (
    <>
        <div className="header">
            <div className="site-logo">
                <img  src={DialogueViewLogo} alt="Logo" />
            </div>
        </div>
    </>
  );
};

export default Header;
