import React, { Component, useEffect, useState, useRef } from "react";
import { Image, Button } from "antd";
import { CONFERENCE_MEET_URL } from "../../Enviornment/enviornment";
import { useSelector, useDispatch } from "react-redux";

import {
  makeAgentEngaged,
  updateConferenceScheduleStatus,
} from "../../actions/conversations";
import wakeLock from "wake-lock";
import { useHistory } from "react-router-dom";
import { checklocalStorage } from "../../util/APIUtility";
import { UTILS } from "../../util/CommonUtilComponent";

function JitsiWindow({
  callType = "audio",
  appointment,
  resize,
  closeModalParent,
}) {
  const [state, setState] = useState();

  let api = null;
  const wakeLockRef = useRef(null);
  const history = useHistory();
  let dispatch = useDispatch();

  const {
    conferenceData,
    agentInfo,
    engagedCustomer,
  } = useSelector((state) => ({ ...state }));
  const { realTimeCallStarted, RealTimeData } = conferenceData;

  useEffect(() => {
    console.log("Resize -->", resize);
    if (window.JitsiMeetExternalAPI) {
      //make agent busy
      onLoadJitsiCall(true, "engaged");
      startMeet();
    } else {
      alert("JitsiMeetExternalAPI not loaded");
    }
  }, []);

  // resize = () => {
  //   console.log("resize called ...!");
  //   if (api) {
  //     api.executeCommand("resizeLargeVideo", {
  //       width: 300, // The desired width
  //       height: 600, // The desired height
  //     });
  //   }
  // };

  const startMeet = () => {
    const DOMAIN = "conferencing.dialogview.ai";

    const options = {
      roomName: appointment.meetingId,
      // width: "100%",
      // height: "100%",
      configOverwrite: {
        prejoinPageEnabled: false,
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        disableRemoteMute: true,
        remoteVideoMenu: {
          disableKick: true,
        },
        disableDeepLinking: true,
        desktopSharingFrameRate: {
          min: 30,
          max: 30,
        },
      },
      interfaceConfigOverwrite: {
        // overwrite interface properties
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        MOBILE_APP_PROMO: false,
        TOOLBAR_BUTTONS: [
          "fullscreen",
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          // "embedmeeting",
          "fodeviceselection",
          "hangup",
          // "profile",
          "chat",
          "recording",
          "livestreaming",
          // "etherpad",
          // "sharedvideo",
          // "shareaudio",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          "feedback",
          // "stats",
          "shortcuts",
          "tileview",
          "select-background",
          "download",
          "help",
          "mute-everyone",
          "mute-video-everyone",
          // "security",
          "whiteboard",
        ],
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: checklocalStorage("userName", null, history) || "User",
      },
      onload: () => {
        updateScheduleStatus(true, history);
        console.log("Request wake lock when component mounts");
        if ("wakeLock" in navigator) {
          requestWakeLock();
        } else {
          console.log("Wake Lock API is not supported in this browser.");
        }
        console.log("JITSI loaded ........!");
      },
    };
    api = new window.JitsiMeetExternalAPI(DOMAIN, options);

    // temp not forcing
    // api.executeCommand("resizeFilmStrip", {
    //   width: 100, // The desired filmstrip width
    // });
    api.addEventListeners({
      readyToClose: handleClose,
      participantLeft: handleParticipantLeft,
      participantJoined: handleParticipantJoined,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
      audioMuteStatusChanged: handleMuteStatus,
      videoMuteStatusChanged: handleVideoStatus,
    });
  };

  const updateScheduleStatus = (callStarted, history) => {
    let body = {
      ...appointment,
      callStarted: callStarted,
    };
    updateConferenceScheduleStatus(body, history)
      .then((res) => {
        console.log("res => ", res);
      })
      .catch((error) => {});
  };

  const onLoadJitsiCall = (IS_ENGAGED = false, STATUS = "online") => {
    dispatch({
      type: "IS_ENGAGED",
      payload: IS_ENGAGED,
    });

    // make agent STATUS to engaged
    agentInfo.status = STATUS;
    dispatch({
      type: "ADD_USERDATA",
      payload: agentInfo,
    });

    // adding engaged details to conference state
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        callEngagedCustomer: STATUS == "online" ? {} : engagedCustomer,
      },
    });

    // make agent status to engaged
    makeAgentEngaged(STATUS, history)
      .then((res) => {
        if (res && res.data && res.data.data) {
          console.log(res.data.data);
        }
      })
      .catch((err) => {
        UTILS.Notification("error", "Error while adding agent to chat");
        console.log(err);
      });
  };

  const handleClose = () => {
    console.log("handleClose");
    updateScheduleStatus(false, history);
    // update agent status to online
    onLoadJitsiCall(false, "online");
    console.log("release wakelog");
    releaseWakeLock();
  };

  const handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
  };

  const handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant);
  };

  const handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant);
  };

  const handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    setState({ jitsiStarted: false });
    api = null;
    closeModal();
    // return props.history.push("/thank-you");
  };

  const handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
    // hideLogo();
    if (audio.muted) {
    } else {
      // enable noise suppression
      setTimeout(() => {
        api.executeCommand("setNoiseSuppressionEnabled", {
          enabled: true, // Enable or disable noise suppression.
        });
      }, 2000);
    }
  };

  const handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  };

  const getParticipants = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  };

  // custom events
  const executeCommand = (command) => {
    api.executeCommand(command);
    if (command == "hangup") {
      // return props.history.push("/thank-you");
    }

    if (command == "toggleAudio") {
      // setState({ isAudioMuted: !isAudioMuted });
    }

    if (command == "toggleVideo") {
      // setState({ isVideoMuted: !isVideoMuted });
    }
  };

  const closeModal = () => {
    setState({ jitsiStarted: true });
    closeModalParent();
    console.log("release wakelog");
    // releaseWakeLock();
    updateScheduleStatus(false, history);
  };

  const requestWakeLock = async () => {
    try {
      wakeLockRef.current = await wakeLock.request("screen");
      console.log("Wake lock activated!");
    } catch (err) {
      console.error("Failed to request wake lock:", err);
    }
  };

  const releaseWakeLock = () => {
    if (wakeLockRef.current) {
      try {
        wakeLockRef.current.release();
        console.log("Wake lock released!");
      } catch (err) {
        console.error("Failed to release wake lock:", err);
      }
    }
  };

  return (
    <>
      <div>
        <header className="nav-bar">
          {/* <p className="item-left heading">Dialogview</p> */}
          <div style={{ display: "flex" }}>
            {/* <div>
                  <Image
                    preview={false}
                    width={50}
                    height={50}
                    src="https://agent.qa.thebanknet.com/agent/api/v1/s3/company/6179183fb5a9093844332b5f/key/221020112703-joon-watermark.png"
                  />
                </div> */}
            {/* <div style={{ fontWeight: "bold" }}>{`${localStorage.getItem(
                  "company"
                )}`}</div> */}
          </div>
        </header>
        <div
          id="jitsi-iframe"
          style={{
            height: `${resize.height - 60}px`,
            width: "100%",
            transition: "all 0.2s ease-in-out",
          }}
        ></div>
      </div>
    </>
  );
}

export default JitsiWindow;
