export const agentReducer = (state = [], action) => {
  console.log("Redux:::", action.payload);
  switch (action.type) {
    case "GET_AGENTSLIST":
      return action.payload;
    default:
      return state;
  }
};

export const agentQueueReducer = (state = [], action) => {
  console.log("Redux:: agentQueueReducer", action.payload);
  switch (action.type) {
    case "AGENT_QUEUE_LIST":
      localStorage.setItem("online_agents", JSON.stringify(action.payload));
      return [...action.payload];
    default:
      return state;
  }
};

export const displayAgentChat = (state = false, action) => {
  switch (action.type) {
    case "DISPLAY_AGENT_CHAT":
      localStorage.setItem(
        "display_agent_chat",
        JSON.stringify(action.payload)
      );
      return action.payload;
    default:
      return state;
  }
};

export const engagedAgentReducer = (state = {}, action) => {
  console.log("engagedAgentReducer ==>", action.payload);
  switch (action.type) {
    case "AGENT_GOTO_ENGAGED":
      localStorage.setItem("engd_Agent", JSON.stringify(action.payload));
      return action.payload;

    default:
      return state;
  }
};
