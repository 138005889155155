import React, { useState } from "react";
import { Menu, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import "./sidebar.scss";
import { useSelector, useDispatch } from "react-redux";
import { IoChatbox, IoCloudOffline } from "react-icons/io5";
import { FaWallet, FaQuestionCircle, FaTh, FaRobot } from "react-icons/fa";
import { IoIosPeople, IoMdCall, IoMdWallet } from "react-icons/io";
import { AiFillInteraction } from "react-icons/ai";
import { VscCallIncoming, VscLightbulbAutofix } from "react-icons/vsc";
import { BsFillClipboard2CheckFill } from "react-icons/bs";
import { TbPhoneCall } from "react-icons/tb";
import { MdSpaceDashboard, MdDashboard, MdHelp } from "react-icons/md";
import ConferenceWindow from "../../components/ConferenceWindow/ConferenceWindow";
import RealTimeCallWindow from "../../components/ConferenceWindow/RealTimeConf";
import { checklocalStorage } from "../../util/APIUtility";
import VoiceCallGlobalWindow from "../ConferenceWindow/VoiceCallGlobalWindow";
import ChatEndPopup from "../AgentReminderTab/ChatEndPopup";
import { useEffect } from "react";
import { userStatusChange } from "../../actions/auth";
import { UTILS } from "../../util/CommonUtilComponent";

const { SubMenu } = Menu;

const Sidebar = () => {
  const history = useHistory();

  const [state, setState] = useState({
    collapsed: true,
  });
  let dispatch = useDispatch();
  const {
    agentInfo,
    isEngaged,
    sidebarSelection,
    conferenceData,
    showChatEndPopup,
  } = useSelector((state) => ({
    ...state,
  }));
  const {
    conferenceStarted,
    appointment,
    realTimeCallStarted,
    voiceCallStarted,
    realTimeData,
  } = conferenceData;

  useEffect(() => {
    handleReduxStateInitial();
    handlingForTabClosedEvent();
  }, []);

  const handleReduxStateInitial = () => {
    // make agent to not engaged

    dispatch({
      type: "IS_ENGAGED",
      payload: false,
    });

    // reset conference state to initial
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        appointment: {},
        conferenceStarted: false,
        realTimeCallStarted: false,
        voiceCallStarted: false,
        callType: "audio",
        callEngagedCustomer: {},
        realTimeData: {},
        load: true,
        mediaType: "",
        voiceCallEngagedCustomer: {},
      },
    });
  };

  // Function to increment the tab count
  const incrementTabCount = () => {
    // Retrieve the last reload timestamp from sessionStorage
    const lastReloadTimestamp = sessionStorage.getItem("lastReloadTimestamp");
    // alert(lastReloadTimestamp);

    // Get the current timestamp
    const currentTimestamp = new Date().getTime();

    // Check if the page has been reloaded in the last 2 seconds (adjust as needed)
    if (!lastReloadTimestamp || currentTimestamp - lastReloadTimestamp > 2000) {
      // Increment the tab count only if the page is not reloaded recently
      let count = localStorage.getItem("tabCount");
      count = count ? parseInt(count) + 1 : 1;
      localStorage.setItem("tabCount", count);

      // Log the current tab count (optional)
      console.log(`Number of tabs opened: ${count}`);

      // Update the last reload timestamp in sessionStorage
      sessionStorage.setItem("lastReloadTimestamp", currentTimestamp);
    }
  };

  // Function to decrement the tab count
  const decrementTabCount = () => {
    // Retrieve the current count from localStorage
    let count = localStorage.getItem("tabCount");

    // If count is present, decrement it
    if (count) {
      count = parseInt(count) - 1;

      const cnt = count < 0 ? 0 : count;
      localStorage.setItem("tabCount", cnt);

      if (cnt == 0) {
        try {
          userStatusChange("offline", agentInfo.id, history)
            .then((res) => {
              if (UTILS.HandleAPIResponse(res.data)) {
                // UTILS.Notification("success", res.data.data);
              }
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        } catch (error) {
          console.log("Error: ", error);
        }
      }

      // Log the current tab count (optional)
      console.log(`Number of tabs opened: ${count}`);
    }
  };

  const handlingForTabClosedEvent = () => {
    // Event listener for page unload (when a tab is closed)
    window.addEventListener("beforeunload", decrementTabCount);
    // Call incrementTabCount when the page loads
    incrementTabCount();
  };

  const handleSidebarSelect = (val) => {
    switch (val) {
      case "/newDashboard":
        return "1";

      case "/":
        return "2";

      case "/onboarding":
        return "3";

      case "/account":
        return "5";

      case "/userManagement":
        return "6";

      case "/integration":
        return "7";

      case "/enquiryListing":
        return "8";

      case "/offline-customers":
        return "9";

      case "/CannedResponses":
        return "10";

      case "/knowledge-base":
        return "12";
      case "/sipphone":
        return "13";
      default:
        break;
    }
  };

  const userRole = checklocalStorage("role", null, history);
  const dialogFlowProjectId = checklocalStorage(
    "companyConfig",
    "dialogFlowProjectId",
    history
  );
  const voipUser = checklocalStorage("agent_info","voipRouting", history);
  const entity = checklocalStorage("companyConfig", "entity", history);
  const { collapsed } = state;

  const toggleCollapsed = () => {
    setState({
      collapsed: !collapsed,
    });
  };

  // const displaySide = () => (
  //   isEngaged ? "" :  <div className="content-area">
  //     <Route exact path="/" component={ConversationComponent} />
  //     <Route path="/tags" component={TagsManager} />
  //     <Route path="/bots" component={BotsManager} />
  //     <Route path="/dash" component={dashboardBox} />
  //     <Route path="/account" component={Account} />
  //     <Route path="/userman" component={UserManage} />
  //     <Route path="/inte" component={Intergration} />
  //     <Route path="/chatHistory" component={ChatHistory} />
  //     <Route path="/AdminSettings" component={AdminSettings} />
  //   </div>
  // )

  const handleSidebarSelectionUpdate = (val) => {
    dispatch({
      type: "SIDEBAR_SELECT",
      payload: val,
    });
    localStorage.setItem("sideBarSelection", val);
  };

  return (
    <div className="sidebar-block">
      <Button
        type="primary"
        className="hamburger-btn"
        onClick={toggleCollapsed}
      >
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
      </Button>
      <div className="sidebar-block__menu">
        <Menu
          //defaultSelectedKeys={['2']}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          // defaultSelectedKeys={[ handleSidebarSelect(history.location.pathname)]}
          selectedKeys={sidebarSelection}
        >
          {/* <Menu.Item disabled={isEngaged ? true : false}  key="1" icon={<TbLayoutDashboard className="sidebar-block__menu--icon" />}>
              { isEngaged ? '': <Link to="/dashboard">DASHBOARD</Link> }
            </Menu.Item> */}
          {userRole === "superadmin" || userRole === "admin" ? (
            <Menu.Item
              key="1"
              icon={<MdDashboard className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("1")}
            >
              <Link to="/newDashboard">DASHBOARD</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {userRole === "superadmin" ||
          userRole === "admin" ||
          userRole === "agent" ? (
            <Menu.Item
              key="2"
              id="side-menu-conversation"
              icon={<IoChatbox className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("2")}
            >
              <Link to="/">CONVERSATIONS</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {userRole === "superadmin" ||
          (userRole === "admin" && entity === "partner") ? (
            <Menu.Item
              key="3"
              icon={
                <BsFillClipboard2CheckFill className="sidebar-block__menu--icon" />
              }
              onClick={() => handleSidebarSelectionUpdate("3")}
            >
              <Link to="/onboarding">ONBOARDING</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {/* { userRole === 'superadmin' || userRole === 'admin' ? (
            <Menu.Item disabled={isEngaged ? true : false}  key="3" icon={<AiFillTag className="sidebar-block__menu--icon" />}>
              { isEngaged ? '': <Link to="/tags">TAGS</Link> } 
            </Menu.Item>
            ) : "" } */}
          {/* {userRole == 'superadmin' || userRole === 'admin' ? (
            <Menu.Item disabled={isEngaged ? true : false}  key="4" icon={<FaRobot className="sidebar-block__menu--icon" />}>
               { isEngaged ? '': <Link to="/bots">BOTS</Link> } 
            </Menu.Item>
            ) : "" } */}
          {userRole === "superadmin" ||
          (userRole === "admin" && entity === "partner") ? (
            <Menu.Item
              key="5"
              icon={<IoMdWallet className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("5")}
            >
              <Link to="/account">ACCOUNT</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {userRole === "superadmin" || userRole === "admin" ? (
            <Menu.Item
              key="6"
              icon={<IoIosPeople className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("6")}
            >
              <Link to="/userManagement">USER MANAGEMENT</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {userRole === "superadmin" || userRole === "admin" ? (
            <Menu.Item
              key="7"
              icon={<AiFillInteraction className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("7")}
            >
              <Link to="/integration">INTEGRATION</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {userRole === "superadmin" ? (
            <Menu.Item
              key="8"
              icon={<FaQuestionCircle className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("8")}
            >
              <Link to="/enquiryListing">ENQUIRY</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {userRole === "superadmin" ||
          userRole == "agent" ||
          userRole === "admin" ? (
            <Menu.Item
              key="9"
              icon={<IoCloudOffline className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("9")}
            >
              <Link to="/offline-customers">OFFLINE</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {userRole === "superadmin" ||
          userRole == "agent" ||
          userRole === "admin" ? (
            <Menu.Item
              key="10"
              icon={
                <VscLightbulbAutofix className="sidebar-block__menu--icon" />
              }
              onClick={() => handleSidebarSelectionUpdate("10")}
            >
              <Link to="/CannedResponses">CANNED RESPONSES</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {/* {userRole === "superadmin" ||
          userRole == "agent" ||
          userRole === "admin" ? (
            <Menu.Item
              key="11"
              icon={
                <TbPhoneCall className="sidebar-block__menu--icon" />
              }
            >
              {isEngaged ? "" : <Link to="/SoftPhone">SOFT PHONE</Link>}
            </Menu.Item>
          ) : (
            ""
          )} */}
          {dialogFlowProjectId === "openai" &&
          (userRole === "superadmin" ||
            userRole == "agent" ||
            userRole === "admin") ? (
            <Menu.Item
              key="12"
              icon={<FaRobot className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("12")}
            >
              <Link to="/knowledge-base">KNOWLEDGE BASE</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {/* CALL LOG COMMENTED FOR DEPLOYMENT */}
          {/* {voipUser &&
          (userRole === "superadmin" ||
            userRole == "agent" ||
            userRole === "admin") ? (
            <Menu.Item
              key="13"
              icon={<IoMdCall className="sidebar-block__menu--icon" />}
              onClick={() => handleSidebarSelectionUpdate("13")}
            >
              <Link to="/sipphone">Softphone</Link>
            </Menu.Item>
          ) : (
            ""
          )} */}
        </Menu>
      </div>
      {conferenceStarted && (
        <ConferenceWindow
          title={appointment.summary || "Conference Call"}
          children={"this is content"}
        />
      )}
      {realTimeCallStarted && (
        <RealTimeCallWindow
          title={realTimeData.summary || "Conference Call"}
          children={"this is content"}
        />
      )}
      {voiceCallStarted && (
        <VoiceCallGlobalWindow
          title={realTimeData.summary || "Voice Call"}
          children={"this is content"}
        />
      )}

      {showChatEndPopup && <ChatEndPopup />}
    </div>
  );
};

export default Sidebar;
