const URL_Properties = {
  SIGN_UP: "api/User",
  ACTIVATE_USER: "api/User/activate",
  GET_CUSTOMER_PHONE: "customer/phone/",
  GET_CUSTOMER_EMAIL: "customer/email/",
  GET_CUSTOMER_ID: "api/v1/customer/",
  SAVE_GUEST_CUSTOMER: "customer",
  ADD_USER: "api/User",
  SEND_EMAIL: "api/v1/email",
  SIGN_OUT: "Auth/LogOut",
  FIND_AGENT: "api/v1/User/status/online",
  SIGN_IN: "Auth/LogIn",
  SIGN_IN_CONFIRMATION: "Auth/LogIn/confirm",
  IF_AGENT: "isAgent?userId=",
  SAVE_CHAT: "api/v1/conversation",
  FETCH_CONV_HISTORY_CLIENT: "/customer/",
  FETCH_CONV_HISTORY_AC: "api/v1/conversation/agent/",
  SEARCH_CONV_HISTORY: "api/v1/conversation/search/",
  CHANGE_STATUS: "/Status/",
  UPDATE_STATUS_MSG: "changeStatusMessage?",
  FETCH_AGENTLIST: "Agent/company/",
  FETCH_TEAMLIST: "api/v1/Team/All/company/",
  FETCH_TEAM_USER: "api/v1/Team/All/User/",
  FETCH_DEPTLIST: "Departments/Company/",
  ADD_DEPTMENT: "api/v1/Department/",
  ADD_TEAM: "api/v1/Team/",
  MODIFY_TEAM: "Agent/Action/",
  MODIFY_DEPT: "api/v1/Team/Action/",
  MODIFY_AGENT: "api/v1/update/user/",
  DELETE_TEAM: "api/v1/Team?",
  DELETE_DEPT: "api/v1/Department/",
  DELETE_AGENT: "api/v1/update/user/",
  GET_USERSETTING: "UserSetting",
  SAVE_MEETING: "Meeting",
  ADD_REMINDER: "api/v1/Job",
  FETCH_REMINDERS: "api/v1/Jobs/user",
  DELETE_REMINDERS: "api/v1/Job/delete",
  CANNED_RESPONSES: "api/v1/CannedMessages/company",
  SAVE_CANNED_RESPONSES: "CannedMessages",
  FETCH_DOCUMENTS_TEAM: "api/v1/Document/Team",
  FETCH_DOCUMENTS_ALL: "api/v1/Document/Teams/Agent",
  FETCH_DOCUMENTS_USER: "api/v1/Document/User/",
  FETCH_CUSTOMERQUEUE: "api/v1/customerqueue/company/",
  UPDATE_CUSTOMERQUEUE: "api/v1/customerqueue/",
  ADD_DOCUMENT: "api/v1/Document",
  SCHEDULE_MEETING: "api/v1/Meeting/Schedule",
  LIST_MEETING: "api/v1/Meeting/Appointments",
  AGENT_MEETING: "api/v1/Meeting/All",
  UPDATE_MEETING: "api/v1/Meeting/Update",
  GET_THEME_LIST: "api/v1/customization/company/",
  UPDATE_CUSTOMIZATION: "api/v1/customization",
  UPDATE_IMAGE: "api/v1/customization/imagefields",
  CANNED_DELETE: "api/v1/CannedMessage/",
  CANNED_ADD: "api/v1/CannedMessage",
  DASHBOARD_GET_DEPTCOUNTS: "dashboard/company/",
  DASHBOARD_GET_CONVCOUNTS: "dashboard/conversationshandled/",
  CONVERSATION_CHANNEL: "api/v1/conversation/channeltype/",
  PIE_CHAT_VALUES: "dashboard/channelconversations/",
  AVG_WAIT_VALUES: "api/v1/averagewaittime/",
  GET_OR_ADD_TWILIO_NUMBERS: "Twilio/numbers",

  // Admin Service API
  GET_ALL_SMS_NUMBER_FROM_COMPANY: "api/v1/subscribe/sms/company/{companyId}",
  ADD_SMS_NUMBER_TO_COMPANY: "api/v1/subscribe/sms",
  DELETE_SMS_NUMBER_FROM_COMPANY: "api/v1/subscribe/sms",
  GET_ALL_WHATSAPP_NUMBER_FROM_COMPANY: "api/v1/subscribe/whatsapp",
  ADD_WHATSAPP_NUMBER_TO_COMPANY: "api/v1/subscribe/whatsapp",
  DELETE_WHATSAPP_NUMBER_FROM_COMPANY: "api/v1/subscribe/whatsapp",

  // Agent API's
  CONFERENCE_SCHEDULE_MEETING: "api/v1/Conference/Schedule",
  CONFERENCE_UPDATE_SCHEDULE_STATUS: "api/v1/appointment/status",
  GET_ALL_APPOINTMENTS: "api/v1/list/Appointments",
  DELETE_MEETING: "api/v1/Meeting/Cancel",

  //  Chat Api's
  SEND_CONFERENCE_INVITE: "conversation/add/guest",
};

export default URL_Properties;
