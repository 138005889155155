import React, { useState } from "react";
import { Modal, Input, Button, Select, Space, DatePicker } from "antd";
import "./agentReminderTab.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  addConversation,
  engagedAgent,
  reminderAdd,
  sendChatEvent,
  getCustomerQueue,
  getReminders,
  sendChatMessage,
  getAgents,
} from "../../actions/conversations";

import { TimeArray } from "../../util/TimeList";
import moment from "moment";
import { UTILS } from "../../util/CommonUtilComponent";
import { useHistory } from "react-router-dom";
import { checklocalStorage } from "../../util/APIUtility";

const { Option } = Select;
const { TextArea } = Input;

const ChatEndPopup = ({ endCall, screenCallEnd }) => {
  let dispatch = useDispatch();
  const history = useHistory();
  const {
    showReminderModal,
    showChatEndPopup,
    engagedCustomer,
    onlineCustomerList,
    jitsiData,
    agentInfo,
    conferenceData,
  } = useSelector((state) => ({ ...state }));
  const { jitsi, mediaType } = jitsiData;
  const {
    realTimeCallStarted,
    RealTimeData,
    callType,
    voiceCallEngagedCustomer,
  } = conferenceData;
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [reminderCheckboxStatus, setreminderCheckboxStatus] = useState(false);
  const [reminder, setReminder] = useState("");
  const [reminderDate, setReminderDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [reminderTime, setReminderTime] = useState(
    moment(moment.unix(Math.floor(Date.now() / 1000)))
      .add(
        30 - (moment.unix(Math.floor(Date.now() / 1000)).minute() % 30),
        "minutes"
      )
      .format("hh:mm A")
  );
  const handleStatus = (value) => {
    setStatus(value);
  };

  const handleNotesChange = (value) => {
    setNotes(value);
  };

  const fetchReminders = () => {
    getReminders(history)
      .then((res) => {
        dispatch({
          type: "GET_REMINDER",
          payload: res.data.data,
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const addReminder = () => {
    var data = JSON.stringify({
      name: reminder,
      scheduledAt: `${reminderDate}T${moment(`${reminderTime}`, ["h:mm A"])
        .utc()
        .format("HH:mm:ss")}Z`,
      // remindWhom: localStorage.getItem("id"),
      // createdBy: localStorage.getItem("id"),
      remindWhom: checklocalStorage("id", null, history),
      createdBy: checklocalStorage("id", null, history),
    });
    reminderAdd(data, history)
      .then((res) => {
        //fetchReminders;
        fetchReminders();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const fetchAgentList = () => {
    getAgents(history)
      .then((res) => {
        const data = res.data.data;
        if (null != data && data.length >= 0) {
          dispatch({
            type: "GET_AGENTSLIST",
            payload: data,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const reminderTimeCheck = () => {
    if (reminderCheckboxStatus) {
      let reminderTZ = moment(`${reminderTime}`, ["h:mm A"]).format("HH:mm");

      if (
        moment(`${reminderDate}T${reminderTZ}`).isAfter(
          moment().add(1, "seconds")
        )
      ) {
        handleCancelModal();
      } else {
        UTILS.Notification("error", "Date & time must not be in the past");
      }
    } else {
      handleCancelModal();
    }
  };

  const handleCancelModal = () => {
    dispatch({ type: "SHOW_CHAT_END_MODAL", payload: false });
    dispatch({
      type: "DISPLAY_CHAT",
      payload: false,
    });

    // clear the canned response
    dispatch({ type: "ACTIVE_PANEL_ACCORDION", payload: "0" });
    dispatch({ type: "ADD_MESSAGE", payload: [] });

    // BELOW CODE IS COMMENTED AS PER AGENT CLIENT NEW FLOW
    //Enable all disabled things while engaged in conversation:-
    //End jitsi here if active
    // if (jitsi !== null) {
    //   if (mediaType === "screenIn") screenCallEnd();
    //   else endCall();
    // }

    // dispatch({ type: "IS_ENGAGED", payload: false });

    //Set agent back to online
    // engagedAgent("online")
    //   .then((res) => {
    //     console.log(res.data);

    //     // refresh the agent list
    //     fetchAgentList();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // // make agent online
    // agentInfo.status = "online";
    // dispatch({
    //   type: "ADD_USERDATA",
    //   payload: agentInfo,
    // });

    console.log(
      "voiceCallEngagedCustomer -->",
      voiceCallEngagedCustomer,
      "engagedCustomer -->",
      engagedCustomer
    );
    const customerInfo = voiceCallEngagedCustomer?.id
      ? voiceCallEngagedCustomer
      : null;

    // adding engaged details to conference state
    // clear conference state
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        callEngagedCustomer: {},
        voiceCallEngagedCustomer: {},
      },
    });

    if (!customerInfo) {
      UTILS.Notification(
        "error",
        "Some error while voice call end, please check after sometime..! "
      );
      return;
    }

    const getDuration = (start_time, end_time) => {
      var diff = end_time - start_time;
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * (1000 * 60 * 60);
      var minutes = Math.floor(diff / 1000 / 60);
      diff -= minutes * (1000 * 60);
      var seconds = Math.floor(diff / 1000);
      return hours + " Hr " + minutes + " Min " + seconds + " Sec ";
    };

    //for sending ending notification to client for whatsapp and sms chat
    if (
      customerInfo.conversationHistory.chatType === "sms" ||
      customerInfo.conversationHistory.chatType === "whatsapp"
    ) {
      let msgObj = {
        // conversationId: JSON.parse(localStorage.getItem("engd_Cust")).convId,
        conversationId: checklocalStorage("engd_Cust", "convId", history),
        messagebody: "Thanks for reaching out to us! Have a good Day!",
        sentBy: "AGENT",
        type: "M",
      };
      sendChatMessage(msgObj, history);
    }

    // call chatEnd true api
    var conversation = {
      id: customerInfo.convId,
      duration: getDuration(
        customerInfo["requestedTime"],
        new Date().getTime()
      ),
      chatType: customerInfo.conversationHistory.chatType.toLowerCase(),
      status: status === "" ? "closed" : status,
      note: notes,
      chatEnd: true,
    };

    var body = JSON.stringify(conversation);
    addConversation(body, history)
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        var msgObj = {
          conversationId: customerInfo.convId,
          messagebody: "CHAT_CLOSED_DISCONNECT_TRIGGER",
          sentBy: "AGENT",
        };
        sendChatEvent(msgObj, history);
        setreminderCheckboxStatus(false);
        localStorage.removeItem("eng_Cust");
        fetchCustomerQueue(history);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    if (reminder !== "") addReminder();
  };

  const fetchCustomerQueue = (history) => {
    getCustomerQueue(history)
      .then((res) => {
        const data = res.data.data;
        if (null != data) {
          let newQueue = [];

          data.forEach((el) => newQueue.push(el));
          dispatch({
            type: "ADD_CUSTOMERS",
            payload: newQueue,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const onChange = (value, dateString) => {
    console.log(value, new Date(dateString).toISOString());

    if (value != null) setReminderDate(new Date(dateString).toISOString());
  };

  const disabledDate = (current) => {
    return current && current <= moment().subtract(1, "d");
  };

  const TimeValCheck = (e) => {
    if (moment(e, "LT", true).isValid()) {
      setReminderTime(e.toUpperCase());
    } else {
      setReminderTime(
        moment(moment.unix(Math.floor(Date.now() / 1000)))
          .add(
            30 - (moment.unix(Math.floor(Date.now() / 1000)).minute() % 30),
            "minutes"
          )
          .format("hh:mm A")
      );
    }
  };

  const reminderContent = reminderCheckboxStatus ? (
    <div className="addreminder-content">
      <div className="addreminder-content__firstbox">
        <div className="addreminder-content__firstbox--input">
          <Input
            placeholder="Add Reminder"
            onChange={(e) => {
              setReminder(e.target.value);
            }}
          />
        </div>
        <div className="reminder-time">
          {/* <Space direction="vertical" size={12}> */}
          {/* <DatePicker
              showTime
              disabledDate={(current) => {
                return moment().add(-1, "days") >= current;
              }}
              onChange={onChange}
            /> */}
          <DatePicker
            allowClear={false}
            format="MM-DD-YYYY"
            popupStyle={{ zIndex: 1005 }}
            style={{ zIndex: 1005, width: "130px" }}
            disabledDate={disabledDate}
            value={moment(reminderDate, "YYYY-MM-DD")}
            onChange={(e) => {
              setReminderDate(e?.format("YYYY-MM-DD"));
            }}
          />
          <span style={{ padding: "5px" }} />
          <div className="select-editable">
            <select
              onChange={(e) => setReminderTime(e.target.value.toUpperCase())}
            >
              {TimeArray.slice(
                TimeArray.indexOf(
                  moment(moment.unix(Math.floor(Date.now() / 1000)))
                    .add(
                      30 -
                        (moment.unix(Math.floor(Date.now() / 1000)).minute() %
                          30),
                      "minutes"
                    )
                    .format("hh:mm A")
                ) + 1
              ).map((val) => (
                <option style={{ margin: "5px auto" }} id={val} value={val}>
                  {val}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="format"
              value={reminderTime}
              onBlur={(e) => TimeValCheck(e.target.value)}
              onChange={(e) => {
                setReminderTime(e.target.val);
              }}
            />
          </div>
          {/* </Space> */}
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div className="agent-remindertab">
      <div id="agentReminderTabModal" className="chat-modal">
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title="Notes & Reminders"
          visible={showChatEndPopup}
          closable={true}
          // onCancel={()=> handleCancelModal()}
          onCancel={() =>
            dispatch({ type: "SHOW_CHAT_END_MODAL", payload: false })
          }
          getContainer="#agentReminderTabModal"
        >
          <div className="reminder-box">
            <div className="notes-header">
              <h3>Add Note and Reminder</h3>
            </div>
            <div className="notes-content">
              <div className="textarea-notes">
                <TextArea
                  placeholder="Description"
                  onChange={(e) => handleNotesChange(e.target.value)}
                  //value={notes}
                  rows={4}
                />
              </div>
              <div className="reminder-checkbox--wrap">
                <div className="reminder-checkbox">
                  <input
                    type="checkbox"
                    checked={reminderCheckboxStatus}
                    onChange={() => {
                      setreminderCheckboxStatus(!reminderCheckboxStatus);
                    }}
                  />
                  <label>Add Reminder</label>
                </div>
                {reminderContent}
              </div>
              <div className="conversation-status">
                <Select
                  placeholder="Select Conversation Status"
                  onChange={handleStatus}
                >
                  <Option value="open">Open</Option>
                  <Option value="closed">Closed</Option>
                </Select>
              </div>
            </div>
            <div className="save-notes">
              <Button
                type="text"
                onClick={() => reminderTimeCheck()}
                className="save-notes__btn"
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ChatEndPopup;
