import Container from "react-bootstrap/Container";
import React, { useEffect, useState } from "react";
import { DialogueViewLogo, loginLeftImage } from "../../images/index";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  loginUserConfirm,
  userStatusChange,
} from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { UTILS } from "../../util/CommonUtilComponent";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../../pages/admin/UserManagemant/userManagement.scss";
import Title from "antd/lib/skeleton/Title";
import Swal from "sweetalert2";
import { Button, Form, Input, notification } from "antd";
import {
  checkIfSessionPresent,
  userId,
  refreshToken,
  checklocalStorage,
} from "../../util/APIUtility";

const Login = () => {
  const { user } = useSelector((state) => ({ ...state }));
  let dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  useEffect(() => {
    checkIfSessionAvailable();
  }, []);

  const checkIfSessionAvailable = () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const userId = localStorage.getItem("id");
    console.log("refreshToken ==>", refreshToken);
    if (refreshToken && userId) {
      const URL = window.location.href;

      if (URL.includes("/login") && userId) {
        history.push("/");
      } else {
        localStorage.removeItem("id");
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        history.push("/login");
      }
    } else {
      localStorage.removeItem("id");
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
      history.push("/login");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const logIn = (values) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const userId = localStorage.getItem("id");

    if (refreshToken && userId) {
      const URL = window.location.href;
      if (URL.includes("/login") && userId) {
        history.push("/");
      }
    } else {
      validateAndLogin(values);
    }
  };

  const validateAndLogin = (values) => {
    setLoginLoading(true);
    var name = values.username;
    var pass = values.password;
    if (null != name && 0 != name.length && null != pass && 0 != pass.length) {
      var data = JSON.stringify({
        password: pass,
        username: name,
      });
      const cancelPopupButton = (
        <>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              notification.close("loginWarning");
              logInConfirmation(name, pass);
            }}
          >
            Confirm
          </Button>
          &nbsp;
          <Button
            size="small"
            onClick={() => notification.close("loginWarning")}
          >
            Cancel
          </Button>
        </>
      );
      try {
        loginUser(data)
          .then((res) => {
            if (res.data.warning) {
              if (res.data.warningList && res.data.warningList.length > 0) {
                if (res.data.warningList[0].errWrnCode === "USROOO9") {
                  console.log("login confirmation");
                  // setIsWarningModalVisible(true);
                  // Swal.fire({
                  //   title:
                  //     "This user already active in another browser. Do you want to logout and continue?",
                  //   // text: "Do you want to continue",
                  //   icon: "question",
                  //   confirmButtonText: "Confirm",
                  //   showCancelButton: true,
                  // }).then((result) => {
                  //   if (result.isConfirmed) {
                  //     logInConfirmation(name, pass);
                  //   }
                  // });
                  UTILS.CustomCloseNotification(
                    "info",
                    "This user already active in another browser. Do you want to logout and continue?",
                    cancelPopupButton,
                    "loginWarning"
                  );
                }
              }
            }
            console.log("Login result: ", res);
            if (UTILS.HandleAPIResponse(res.data)) {
              const userInfo = res.data.data;
              dispatch({
                type: "LOGGED_IN_USER",
                payload: {
                  user: userInfo.user.userIdentifier,
                  token: userInfo.authToken,
                  role: userInfo.user.role,
                },
              });
              dispatch({
                type: "SIDEBAR_SELECT",
                payload: "2",
              });
              var role = userInfo.user.role;
              var userName = userInfo.user.firstName;
              var email = userInfo.user.email;
              var token = userInfo.authToken;
              var refreshToken = userInfo.refreshToken;
              var id = userInfo.user.id;
              var company = userInfo.user.companyConfig.company;

              localStorage.setItem("auth", "checked");
              localStorage.setItem("userName", userName);
              localStorage.setItem("email", email);
              localStorage.setItem("userKey", pass);
              localStorage.setItem("authToken", token);
              localStorage.setItem("refreshToken", refreshToken);
              localStorage.setItem("id", id);
              localStorage.setItem("role", role);
              localStorage.setItem("company", company);
              localStorage.setItem(
                "companyConfig",
                JSON.stringify(userInfo.user.companyConfig)
              );
              history.push("/");

              // update user status to online
              userStatusChange("online", id, history)
                .then((res) => {
                  if (UTILS.HandleAPIResponse(res.data)) {
                    // UTILS.Notification("success", res.data.data);
                  }
                })
                .catch((error) => {
                  console.error("Error while updating user status ::", error);
                });
            }
            setLoginLoading(false);
          })
          .catch((err) => {
            document.getElementById("errmsg").innerHTML =
              '<p class="login-error">Invalid UserName/Password</p>';
            console.log(err);
            setLoginLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoginLoading(false);
      }
    }
  };

  const logInConfirmation = (username, password) => {
    console.log("LOGINCONFIRMATION");
    var name = username;
    var pass = password;

    if (null != name && 0 != name.length && null != pass && 0 != pass.length) {
      var data = JSON.stringify({
        password: pass,
        username: name,
      });
      try {
        loginUserConfirm(data)
          .then((res) => {
            console.log("Login result: ", res);
            if (UTILS.HandleAPIResponse(res.data)) {
              const userInfo = res.data.data;
              dispatch({
                type: "LOGGED_IN_USER",
                payload: {
                  user: userInfo.user.userIdentifier,
                  token: userInfo.authToken,
                  role: userInfo.user.role,
                },
              });
              dispatch({
                type: "SIDEBAR_SELECT",
                payload: "2",
              });
              var role = userInfo.user.role;
              var userName = userInfo.user.firstName;
              var email = userInfo.user.email;
              var token = userInfo.authToken;
              var refreshToken = userInfo.refreshToken;
              var id = userInfo.user.id;
              var company = userInfo.user.companyConfig.company;

              localStorage.setItem("auth", "checked");
              localStorage.setItem("userName", userName);
              localStorage.setItem("email", email);
              localStorage.setItem("userKey", pass);
              localStorage.setItem("authToken", token);
              localStorage.setItem("refreshToken", refreshToken);
              localStorage.setItem("id", id);
              localStorage.setItem("role", role);
              localStorage.setItem("company", company);
              localStorage.setItem(
                "companyConfig",
                JSON.stringify(userInfo.user.companyConfig)
              );
              history.push("/");
            }
          })
          .catch((err) => {
            document.getElementById("errmsg").innerHTML =
              '<p class="login-error">Invalid UserName/Password</p>';
            console.log(err);
          });
      } catch (error) {
        console.log("LogIn Confirmation Error", error);
      }
    }
  };
  const checkRole = (role, userId) => {
    // console.log(role);
    role = role.toLowerCase();
    if (null != role && null != userId) {
      if (role === "agent") history.push("/");
      if (role === "admin") history.push("/userman");
      if (role === "guest") {
        history.push("/userman");
      }
      if (role === "manager") {
        history.push("/userman");
      }
    } else {
      history.push("/");
    }
  };

  return (
    <Container fluid className="p-0">
      <div className="login">
        <div className="loginpage-wrapper">
          <div className="login-image">
            <img
              className="login-image__left"
              src={loginLeftImage}
              alt="Login left Image"
            />
          </div>
          <div className="login-box">
            <div className="logo">
              <img src={DialogueViewLogo} alt="logo" />
              <p>Creating Meaningful Conversations Everytime</p>
            </div>

            <div className="form">
              {/* <form>
                <input
                  type="text"
                  placeholder="Email ID"
                  className="input-text"
                  id="user"
                  required
                />
                <div
                  className="password-input"
                  style={{ position: "relative" }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Password"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        logIn();
                      }
                    }}
                    id="pass"
                    className="input-text"
                  />
                  {showPassword ? (
                    <EyeInvisibleOutlined
                      className="eye-icon"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <EyeOutlined
                      className="eye-icon"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </div>
                <div className="login-btn-wrapper">
                  <a href="#" onClick={logIn} className="btn-login">
                    Login
                  </a>
                  <Link to="/forgot-password" className="btn-forgot">
                    Forgot Password?
                  </Link>
                </div>
                <div id="errmsg"></div>
              </form> */}
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={logIn}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email ID!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="email"
                    className="login-form-input"
                    placeholder="Email ID"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    className="login-form-input"
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <div className="login-footer">
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}
                  <Form.Item noStyle>
                    <Button
                      htmlType="submit"
                      loading={loginLoading}
                      className="login-form-button"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                  <Link to="/forgot-password" className="login-form-forgot">
                    Forgot Password?
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Login;
