import React, { useState, useEffect } from "react";
import "../SignUpForm/signUpForm.scss";
import { Form, Input, Button, Row } from "antd";
import { activateUser, resetPassword } from "../../actions/auth";
import { DialogueViewLogo } from "../../images";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { UTILS } from "../../util/CommonUtilComponent";

const ActivateUser = (props) => {
  const [form] = Form.useForm();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,16}$/;

  const onFinish = (values) => {
    console.log("Received values of form: ", values);

    console.log("validating password");
    if (passwordPattern.test(values.confirm)) {
      setIsLoading(true);
      setIsLoading(true);

      setIsLoading(true);

      if (history.location.pathname.includes("personal-info")) {
        let data = JSON.stringify({
          email: props.match.params.email,
          password: values.confirm,
          userId: props.match.params.userId,
        });
        activateUser(data, history)
          .then((res) => {
            console.log(res.data);
            setTimeout(() => {
              history.push("/login");
            }, 500);
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      } else if (history.location.pathname.includes("reset-password")) {
        let data = JSON.stringify({
          newPassword: values.confirm,
          passwordResetToken: props.match.params.token,
          userName: props.match.params.userName,
        });
        resetPassword(data, history)
          .then((res) => {
            console.log(res.data);
            setTimeout(() => {
              history.push("/login");
            }, 500);
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      } else {
        console.log("password error");
        UTILS.Notification(
          "error",
          "Password should be more than 5 characters, at least one uppercase alphabet, a lowercase alphabet, a number and a special character."
        );
      }
    }
  };

  const title = () =>
    history.location.pathname.includes("personal-info")
      ? "You have successfully verified the email. Please set the password."
      : "Reset Password";
  return (
    <>
      <div className="form-wrapper">
        <div className="form-wrapper__content">
          <div className="logo-wrapper">
            <img src={DialogueViewLogo} alt="Logo" />
          </div>
          <h2 className="form-h2">{title()}</h2>
          <div className="signin-form signup-form">
            <Form
              // {...formItemLayout}
              form={form}
              name="setPassword"
              labelAlign="left"
              layout="horizontal"
              onFinish={onFinish}
              initialValues={{
                prefix: "91",
              }}
              scrollToFirstError
              style={{ width: "400px" }}
            >
              <Form.Item
                name="password"
                // label= {history.location.pathname.includes('personal-info') ? "Password" : "New Password" }
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,16}$/,
                    message: `at least one uppercase alphabet, a lowercase alphabet, a number and a special character.`,
                  },
                  {
                    min: 5,
                    message: "The password must contain at least 5 characters.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Row style={{ margin: "10px" }}></Row>

              <Form.Item
                name="confirm"
                // label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter confirm password",
                  },
                  {
                    min: 5,
                    message: "Please enter at least 5 characters!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Please enter matching passwords!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>

              <Row style={{ margin: "10px" }}></Row>

              <Form.Item
              // {...tailFormItemLayout}
              >
                <div style={{ display: "flex", margin: "10px" }}>
                  <div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="create-account"
                    >
                      Set Password
                    </Button>
                  </div>
                  {isLoading && (
                    <div
                      style={{
                        marginLeft: "2%",
                        fontWeight: "bold",
                        lineHeight: "30px",
                      }}
                    >
                      <p>Please wait...</p>
                    </div>
                  )}
                  {isLoading && (
                    <div style={{ marginLeft: "2%" }}>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden"></span>
                      </Spinner>
                    </div>
                  )}
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivateUser;
