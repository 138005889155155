const initialState = {
  appointment: {},
  conferenceStarted: false,
  realTimeCallStarted: false,
  voiceCallStarted: false,
  callType: "audio",
  callEngagedCustomer: {},
  realTimeData: {},
  loading: false,
  mediaType: "",
  voiceCallEngagedCustomer: {},
  eventDescription: "",
  agentChatPopup: false,
  agentChatPopupMessage: "",
  isAgentToAgentCall: false,
};

export const conferenceWindowReducers = (state = initialState, action) => {
  switch (action.type) {
    case "CONFERENCE_UPDATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
