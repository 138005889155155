const initialState = {
  jitsi: null,
  meet: false,
  load: true,
  mediaType: "",
  outgoing: false,
  incoming: false,
  accepted: false,
  rejected: false,
  screenOff: false,
  audiOff: false,
  videoForAudio: false,
  maximize: true,
  customer: {},
};

export const jitsiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "JITSI_UPDATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
