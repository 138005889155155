import React from 'react'
import { Redirect } from 'react-router-dom'
import { checklocalStorage } from '../util/APIUtility';

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        const isAuthenticated = localStorage.getItem('auth') === 'checked' ? true:false;
        return isAuthenticated ? (
            <Component {...this.rest} {...this.props}/>
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }

   
}

export default ProtectedRoute;


