import React, { Component, Suspense, lazy } from "react";
// import Tags from "../../pages/conversation/Tags";
// import Bots from "../../pages/conversation/Bots";
import Spinner from "../Toastify/Spinner/spinner";
import { Route } from "react-router-dom";
import "./contentArea.scss";
// import ScreenInterceptor from "../../ScreenInterceptor";

const ConversationComponent = lazy(() =>
  import("../Conversation/conversationBox")
);
const ChatHistory = lazy(() => import("../ChatHistory/chatHistory"));
const Account = lazy(() => import("../AccountsOverview/accouts"));
const Enquiry = lazy(() => import("../EnquiryList/enquiry"));
const OfflineCustomers = lazy(() =>
  import("../OfflineCustomers/offlineCustomers")
);
const UserManage = lazy(() =>
  import("../../pages/admin/UserManagemant/userManagement")
);
const Intergration = lazy(() => import("../../pages/admin/Intergration"));
const AdminSettings = lazy(() =>
  import("../../pages/admin/AdminSettings/adminSettings")
);
const ProfileSettings = lazy(() =>
  import("../../components/ProfileSettings/profileSettings")
);
const BotConfiguration = lazy(() =>
  import("../../pages/admin/Bots/BotConfiguration/botConfiguration")
);
const CannedResponses = lazy(() =>
  import("../../pages/admin/CannedResponses/cannedResponses")
);
const Onboarding = lazy(() => import("../Onboarding/onboarding"));
const SoftPhone = lazy(() => import("../../pages/admin/SoftPhone/softphone"));
const BotsManager = lazy(() => import("../../pages/admin/Bots/bots"));
const TagsManager = lazy(() => import("../../pages/admin/Tags/tags"));
const dashboardBox = lazy(() =>
  import("../../pages/admin/Dashboard/dashboard")
);
const Dashboard = lazy(() => import("../Dashboard/dashboard"));
const AgentChat = lazy(() => import("../../pages/Chats"));
const AccountDetail = lazy(() =>
  import("../AccountsOverview/AccountDetail/AccountDetail.js")
);
const UploadKnowledgeBase = lazy(() => import("../UploadKnowledgeBase"));
const SipPhone = lazy(() => import("../../pages/admin/SIPPhone/SIPPhone.js"));

class ContentArea extends Component {
  render() {
    return (
      <div className="content-area">
        <Suspense fallback={<Spinner />}>
          {/* <Route exact path="/" component={ConversationComponent} /> */}
          <Route exact path="/" component={AgentChat} />
          <Route path="/tags" component={TagsManager} />
          <Route path="/bots" component={BotsManager} />
          <Route path="/dashboard" component={dashboardBox} />
          <Route path="/newDashboard" component={Dashboard} />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/account" component={Account} />
          <Route path="/accountDetail" component={AccountDetail} />
          <Route path="/userManagement" component={UserManage} />
          <Route path="/integration" component={Intergration} />
          <Route path="/enquiryListing" component={Enquiry} />
          <Route path="/offline-customers" component={OfflineCustomers} />
          <Route path="/chatHistory" component={ChatHistory} />
          <Route path="/AdminSettings" component={AdminSettings} />
          <Route path="/ProfileSettings" component={ProfileSettings} />
          <Route path="/CannedResponses" component={CannedResponses} />
          <Route path="/BotConfiguration" component={BotConfiguration} />
          <Route path="/SoftPhone" component={SoftPhone} />
          <Route path="/knowledge-base" component={UploadKnowledgeBase} />
          {/* SIPPhone commented for temp Deployement */}
          {/* <Route path="/sipphone" component={SipPhone} /> */}
        </Suspense>
      </div>
    );
  }
}

export default ContentArea;
