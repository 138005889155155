import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import 'antd/dist/antd.css';
import "./index.css";
import "../src/scss/common.scss";
import "../src/scss/theme.scss";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";

import Routes from "./routes/Routes";

const App = () => {
  return (
    <Routes />
  )
}
  export default App;