import React, { useEffect, useState } from "react";
import { Button, Tooltip, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./real-time-call-window.scss";
import Draggable from "react-draggable";
import { useHistory } from "react-router-dom";
import {
  FaWindowMinimize,
  FaWindowMaximize,
  FaWindowClose,
} from "react-icons/fa";

import { TbMessage2Down } from "react-icons/tb";

import {
  sendChatEvent,
  makeAgentEngaged,
  getAgents,
  getCustomerQueue,
  addConversation,
} from "../../actions/conversations.js";

import { Resizable, ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Swal from "sweetalert2";
import { UTILS } from "../../util/CommonUtilComponent.js";
import { checklocalStorage } from "../../util/APIUtility.js";
import PSTNMenu from "../DropdownPSTN/dropdownPSTN.js";

const VoiceCallGlobalWindow = ({ title, children }) => {
  let dispatch = useDispatch();
  const history = useHistory();

  const {
    conferenceData,
    agentInfo,
    engagedCustomer,
  } = useSelector((state) => ({ ...state }));
  const {
    voiceCallStarted,
    RealTimeData,
    callType,
    voiceCallEngagedCustomer,
  } = conferenceData;
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth - 500,
    height: window.innerHeight - 100,
  });
  const [position, setPosition] = useState({ x: 300, y: -200 });

  useEffect(() => {
    //make agent busy
    onLoadJitsiCall(true, "engaged");

    startJitsiCall(callType || "audio");

    // Attach the resize event listener
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleMinimize = () => {
    setIsMinimized(true);
    setPosition({ x: 100, y: window.innerHeight - 500 }); // Adjust the values as needed
    setWindowSize({ height: 200, width: 300 });
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setPosition({ x: 300, y: -200 });
    setWindowSize({
      width: window.innerWidth - 500,
      height: window.innerHeight - 100,
    });
  };

  const handleExpand = () => {
    setIsMaximized(!isMaximized);
  };
  const handleGoToMessage = () => {
    // minimize window
    handleMinimize();
    // focus to input
    let InputBox = document.getElementById("chatSend");
    if (InputBox) InputBox.focus();

    // open conversation page if user in other page
    let side_menu_conversation = document.getElementById(
      "side-menu-conversation"
    );
    if (side_menu_conversation) {
      // check if user in other page
      if (side_menu_conversation.classList.contains("ant-menu-item-selected")) {
        dispatch({ type: "SHOW_REMINDER_MODAL", payload: true });
      } else {
        // open conversation page and open chat panel
        history.push("/");
        side_menu_conversation.click();
        setTimeout(() => {
          dispatch({ type: "SHOW_REMINDER_MODAL", payload: true });
        }, 2000);
      }
    } else {
      dispatch({ type: "SHOW_REMINDER_MODAL", payload: true });
    }
  };

  const cancelPopupButton = (
    <>
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close("callWarning");
          handleVoiceCallEnd();
        }}
      >
        Confirm
      </Button>
      &nbsp;
      <Button size="small" onClick={() => notification.close("callWarning")}>
        Cancel
      </Button>
    </>
  );

  const handleClose = () => {
    // if yes update conference status to false and close the window
    // Swal.fire({
    //   title: "You will be removed from the Call",
    //   // text: "Do you want to continue",
    //   icon: "question",
    //   confirmButtonText: "ok",
    //   showCancelButton: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     dispatch({
    //       type: "CONFERENCE_UPDATE",
    //       payload: {
    //         voiceCallStarted: false,
    //       },
    //     });

    //     // update agent status to online
    //     onLoadJitsiCall(false, "online");
    //   }

    //   // send end call event
    //   var msgObj = {
    //     conversationId: JSON.parse(localStorage.getItem("engd_Cust")).convId,
    //     messagebody: `CALL_ENDED`,
    //     sentBy: "AGENT",
    //     type: "JITSI",
    //   };
    //   sendChatEvent(msgObj, history);
    // });

    UTILS.CustomCloseNotification(
      "info",
      "You will be disconnect from the Voice Call !",
      cancelPopupButton,
      "callWarning"
    );
  };

  const handleResize = (event, { element, size }) => {
    // Handle resize action here
    // The 'size' parameter contains the new width and height after resizing
    // You can access size.width and size.height to update your component's state
    // console.log("New size:", size);
    setWindowSize(size);
  };

  const handleWindowResize = () => {
    // Adjust the position based on zoom level and window dimensions
    const adjustedX = position.x * (window.innerWidth / window.outerWidth);
    const adjustedY = position.y * (window.innerHeight / window.outerHeight);
    setPosition({ x: adjustedX, y: adjustedY });
  };

  const handleDrag = (e, data) => {
    const { clientX, clientY } = e;

    console.log("e ->", e, "data -> ", data);
    const { offsetWidth, offsetHeight } = data.node;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const maxX = windowWidth - offsetWidth;
    const maxY = windowHeight - offsetHeight;

    const x = Math.min(Math.max(0, clientX - data.x), maxX);
    const y = Math.min(Math.max(0, clientY - data.y), maxY);

    setPosition({
      x: data.x,
      y: data.y,
    }); // Update position while dragging
  };

  const startJitsiCall = async (forAudioOrVideo = "audio") => {
    console.log("Starting Real Time >>>>>>>>>>>>>>>>>>");

    const roomName = checklocalStorage("meetingId", null, history);
    const agentName =
      checklocalStorage("userName", null, history) ||
      `Agent-${new Date().getTime().toString().slice(-6)}`;

    // dispatch({
    //   type: "JITSI_UPDATE",
    //   payload: {
    //     mediaType: "video",
    //     meet: true,
    //     outgoing: false,
    //     accepted: true,
    //   },
    // });

    const jitsiContainerId = "voice-call-container";
    const loadJitsiScript = () => {
      let resolveLoadJitsiScriptPromise = null;

      const loadJitsiScriptPromise = new Promise((resolve) => {
        resolveLoadJitsiScriptPromise = resolve;
      });

      const script = document.createElement("script");
      script.src = "https://meet-qa.dialogview.ai/external_api.js";
      script.async = true;
      script.onload = () => resolveLoadJitsiScriptPromise(true);
      document.body.appendChild(script);

      return loadJitsiScriptPromise;
    };

    if (!window.JitsiMeetExternalAPI) {
      dispatch({
        type: "JITSI_UPDATE",
        payload: {
          load: true,
        },
      });
      await loadJitsiScript();
    }

    console.log("videoForAudio -->", forAudioOrVideo);

    const videoOptions = {
      roomName: roomName,
      parentNode: document.getElementById(jitsiContainerId),
      userInfo: {
        displayName: agentName,
      },
      configOverwrite: {
        doNotStoreRoom: true,
        // startVideoMuted: 4,
        startWithVideoMuted: true,
        // startAudioOnly: false,
        startWithAudioMuted: false,
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        disableRemoteMute: true,
        remoteVideoMenu: {
          disableKick: true,
        },
        disableDeepLinking: true,
        disableSelfViewSettings: true,
      },
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        FILM_STRIP_MAX_HEIGHT: 120,
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        DEFAULT_REMOTE_DISPLAY_NAME: "New User",
        BRAND_WATERMARK_LINK: "https://dialogview.com/",
        TOOLBAR_BUTTONS: ["microphone", "hangup"],
      },
      onload: function () {
        dispatch({
          type: "JITSI_UPDATE",
          payload: {
            load: false,
          },
        });

        // do action on load
        // onLoadJitsiCall(true, "engaged");
      },
    };

    const apiObj = new window.JitsiMeetExternalAPI(
      "meet-qa.dialogview.ai",
      videoOptions
    );

    dispatch({
      type: "JITSI_UPDATE",
      payload: {
        jitsi: apiObj,
      },
    });

    apiObj.addEventListeners({
      readyToClose: () => {
        handleVoiceCallEnd();
      },
      filmstripDisplayChanged: function (event) {
        console.log("filmstrip display changed-->", event);
        if (!event.visible) {
          apiObj.executeCommand("toggleFilmStrip");
        }
      },
      videoMuteStatusChanged: function (event) {
        console.log("videoMuteStatusChanged -->", event);
        if (event.muted) {
          console.log("videoMuteStatusChanged MUTED", event);
          // setVideoMute(true);
        } else {
          console.log("videoMuteStatusChanged FALSE", event);
          // setVideoMute(false);
        }
      },
      audioMuteStatusChanged: function (event) {
        console.log("audioMuteStatusChanged -->", event);
        if (event.muted) {
          // setAudioMute(true);
        } else {
          // setAudioMute(false);
          // enable noise suppression
          setTimeout(() => {
            apiObj.executeCommand("setNoiseSuppressionEnabled", {
              enabled: true, // Enable or disable noise suppression.
            });
          }, 2000);
        }
      },
    });

    apiObj.executeCommand("subject", roomName);
    apiObj.executeCommand("toggleFilmStrip");
  };

  const getDuration = (start_time, end_time) => {
    var diff = end_time - start_time;
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * (1000 * 60 * 60);
    var minutes = Math.floor(diff / 1000 / 60);
    diff -= minutes * (1000 * 60);
    var seconds = Math.floor(diff / 1000);
    return hours + " Hr " + minutes + " Min " + seconds + " Sec ";
  };

  const fetchCustomerQueue = (history) => {
    getCustomerQueue(history)
      .then((res) => {
        const data = res.data.data;
        if (null != data) {
          let newQueue = [];

          data.forEach((el) => newQueue.push(el));
          dispatch({
            type: "ADD_CUSTOMERS",
            payload: newQueue,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const CHAT_CLOSE_FROM_QUEUE = () => {
    const customerInfo = voiceCallEngagedCustomer?.id
      ? voiceCallEngagedCustomer
      : null;
    if (!customerInfo) {
      UTILS.Notification(
        "error",
        "Some error while voice call end, please check after sometime..! "
      );
    }
    // call chatEnd true api
    var conversation = {
      id: customerInfo.convId,
      duration: getDuration(
        customerInfo["requestedTime"],
        new Date().getTime()
      ),
      chatType: customerInfo.conversationHistory.chatType.toLowerCase(),
      status: "closed",
      note: "",
      chatEnd: true,
    };

    var body = JSON.stringify(conversation);
    addConversation(body, history)
      .then((res) => {
        const data = res.data.data;
        console.log(data);

        var msgObj = {
          conversationId: customerInfo.convId,
          messagebody: "CHAT_CLOSED_DISCONNECT_TRIGGER",
          sentBy: "AGENT",
        };
        sendChatEvent(msgObj, history);
        fetchCustomerQueue(history);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleVoiceCallEnd = () => {
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        voiceCallStarted: false,
      },
    });

    // send end call event
    var msgObj = {
      // conversationId: JSON.parse(localStorage.getItem("engd_Cust")).convId,
      conversationId: checklocalStorage("engd_Cust", "convId", history),
      messagebody: `CALL_ENDED`,
      sentBy: "AGENT",
      type: "JITSI",
    };
    sendChatEvent(msgObj, history);

    // update agent status to online
    onLoadJitsiCall(false, "online");

    // removing voice call from queue
    CHAT_CLOSE_FROM_QUEUE();

    // show chat end popup
    dispatch({ type: "SHOW_CHAT_END_MODAL", payload: true });
  };

  const onLoadJitsiCall = (IS_ENGAGED = false, STATUS = "online") => {
    dispatch({
      type: "IS_ENGAGED",
      payload: IS_ENGAGED,
    });

    // make agent STATUS to engaged
    agentInfo.status = STATUS;
    dispatch({
      type: "ADD_USERDATA",
      payload: agentInfo,
    });

    // make agent status to engaged
    makeAgentEngaged(STATUS, history)
      .then((res) => {
        if (res && res.data && res.data.data) {
          console.log(res.data.data);

          //refresh the agent list
          fetchAgentList();
        }
      })
      .catch((err) => {
        UTILS.Notification("error", "Error while adding agent to chat");
        console.log(err);
      });
  };

  const fetchAgentList = () => {
    getAgents(history)
      .then((res) => {
        const data = res.data.data;
        if (null != data && data.length >= 0) {
          dispatch({
            type: "GET_AGENTSLIST",
            payload: data,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div>
      {voiceCallStarted && (
        <Draggable
          onDrag={handleDrag}
          handle=".window-title"
          defaultPosition={{ x: 10, y: 10 }}
          position={position}
          // grid={[25, 25]}
          scale={1}
          bounds="body"
        >
          <div
            className="resizable-window"
            style={{ height: windowSize.height, width: windowSize.width }}
          >
            <div className="window-title">
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Tooltip title={title}>{`Voice Call - ${title}`}</Tooltip>
              </div>

              <div className="window-actions">
                <div className="chat-panel__header--right">
                  <PSTNMenu />
                  <FaWindowMinimize
                    onClick={handleMinimize}
                    className="window-action"
                  />
                  <FaWindowMaximize
                    onClick={handleMaximize}
                    className="window-action"
                  />
                  <FaWindowClose
                    onClick={handleClose}
                    className="window-action"
                  />
                </div>
              </div>
            </div>
            {/* <div className="window-content">{children}</div>
            <div className="resize-handle" onMouseDown={handleResize} /> */}

            <div
              className="window-content"
              // style={{ height: windowSize.height, width: windowSize.width }}
            >
              {/* Window content goes here */}
              <div
                id="voice-call-container"
                style={{
                  height: `${windowSize.height - 60}px`,
                  width: "100%",
                  transition: "all 0.2s ease-in-out",
                }}
              />
            </div>
            {/* 
            <Resizable
              width={windowSize.width}
              height={windowSize.height}
              minConstraints={[300, 200]}
              maxConstraints={[window.innerWidth, window.innerHeight]}
              onResize={handleResize}
            >
              <div className="resize-handle" />
            </Resizable> */}
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default VoiceCallGlobalWindow;
