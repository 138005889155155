export const AUTHENTICATION_SERVICE = process.env.REACT_APP_AUTH_URL;
export const CHAT_SERVICE = process.env.REACT_APP_CHAT_URL;
export const ADMIN_SERVICE = process.env.REACT_APP_ADMIN_URL;
export const AGENT_SERVICE = process.env.REACT_APP_AGENT_URL;
export const SOCKET_SERVICE = process.env.REACT_APP_SOCKET_URL;
export const INVITE_MEET_URL = process.env.REACT_APP_INVITE_MEET_URL;
export const LIVE_MEET_URL = process.env.REACT_APP_LIVE_CALL_URL;
export const CONFERENCE_MEET_URL = process.env.REACT_APP_CONFERENCE_URL;
export const CHATBOT_BASE_URL = process.env.REACT_APP_CHATBOT_BASE_URL;

