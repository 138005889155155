import { Form, Input, Button } from "antd";
import { DialogueViewLogo } from "../../images";
import { forgotPassword } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import "./dialogviewForget.scss";
import { useState } from "react";

const DialogviewForget = () => {
  let history = useHistory();
  const onFinish = (values) => {
    var data = JSON.stringify({
      userName: values.userName,
      origin: window.location.origin,
    });
    forgotPassword(data,history)
      .then((res) => {
        console.log(res.data);
        history.push("/verify-password");
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setErr(true);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [err, setErr] = useState(false);

  return (
    <div className="form-wrapper">
      <div className="form-wrapper__content">
        <div className="logo-wrapper">
          <img src={DialogueViewLogo} alt="Logo" />
        </div>
        <h2 className="form-h2">Forgot Password</h2>
        <div className="signin-form signup-form">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Enter a valid email id",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <br></br>

            <Form.Item className="btn_wrap">
              <Button
                type="primary"
                htmlType="submit"
                className="create-account"
              >
                Submit
              </Button>
            </Form.Item>
            {err ? (
              <>
                <div className="err_wrap">
                  <span className="err_msg">
                    {" "}
                    No active user with this mail-id!{" "}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default DialogviewForget;
