import React, { useState } from "react";
import { Popover, Button, Input, Select, notification, Tooltip } from "antd";
import "./dropdownPSTN.scss";
import {
  CHAT_SERVICE,
  INVITE_MEET_URL,
} from "../../../src/Enviornment/enviornment.js";
import {
  sendInviteMail,
  sendInviteConference,
} from "../../actions/conversations";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { UTILS } from "../../util/CommonUtilComponent";
import { useHistory } from "react-router-dom";
import { checklocalStorage } from "../../util/APIUtility";
import { useSelector, useDispatch } from "react-redux";

var agentUidUrl = "";
agentUidUrl = CHAT_SERVICE;

function PSTNMenu() {
  const [pstnShow, setpstnShow] = useState(false);
  const [selValue, setselValue] = useState("email");
  const [smsoremail, setSmsoremail] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const history = useHistory();

  const { Option } = Select;
  const {
    showReminderModal,
    engagedCustomer,
    onlineCustomerList,
    jitsiData,
    agentInfo,
    conferenceData,
  } = useSelector((state) => ({ ...state }));
  const {
    realTimeCallStarted,
    RealTimeData,
    callType,
    voiceCallEngagedCustomer,
  } = conferenceData;

  // const meetURL = `https://dialogview.com/`
  const meetURL =
    checklocalStorage("engd_Cust", null, history) &&
    checklocalStorage("engd_Cust", "customer", history) &&
    checklocalStorage("engd_Cust", "customer.siteUrl", history)
      ? `https://${
          // JSON.parse(localStorage.getItem("engd_Cust")).customer.siteUrl
          checklocalStorage("engd_Cust", "customer.siteUrl", history)
        }/`
      : voiceCallEngagedCustomer?.id
      ? voiceCallEngagedCustomer?.companyConfig?.integrationSites[0]
      : INVITE_MEET_URL;

  function smsEmailHandleChange(value) {
    setselValue(value);
    setSmsoremail("");
    console.log(value);
    // alert(`selected ${value}` +selValue);
  }

  const handleChange = (event) => {
    setSmsoremail(event.target.value);
  };

  function callEmailFunction() {
    // //alert('selValue'+selValue);
    // if (selValue == "email") {
    //   // var email=document.getElementById('smsoremailId').value;
    //   //alert('email'+email);
    //   inviteWithEmail(smsoremail);
    //   setpstnShow(false);
    //   setselValue("");
    //   setSmsoremail("");
    // } else {
    //   // var number=document.getElementById('smsoremailId').value;
    //   //alert('number'+number);
    //   sendurlSms(smsoremail);
    //   setselValue("");
    // }
    // document.getElementById("smsoremailId").value = "";

    // new code
    sendInvite();
  }

  const validateSMSorEmail = (callback) => {
    let valid = true;
    let inviteList =
      smsoremail && smsoremail.includes(",")
        ? smsoremail.split(",")
        : [smsoremail];
    inviteList.forEach((inviteSmsOrEmail) => {
      console.log("inviteSmsOrEmail -->", inviteSmsOrEmail);
      if (selValue == "email") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inviteSmsOrEmail)
        ) {
          valid = true;
        } else valid = false;
      } else {
        if (
          /^([+]\d{1,2})[ ]?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(
            inviteSmsOrEmail
          )
        ) {
          valid = true;
        } else valid = false;
      }
    });
    callback(valid);
  };

  const sendInvite = () => {
    // preparing body

    if (!smsoremail) {
      setErrorStatus("error");
      openNotificationWithIcon("warning", "Please enter required Input!");
    } else {
      validateSMSorEmail((isValid) => {
        if (isValid) {
          let inviteList =
            smsoremail && smsoremail.includes(",")
              ? smsoremail.split(",")
              : [smsoremail];
          let attendeesList = [];
          if (inviteList.length > 0) {
            inviteList.map((inviteSmsOrEmail) =>
              attendeesList.push({
                contactChannel: selValue,
                contactChannelId: inviteSmsOrEmail,
              })
            );
          }
          let data = {
            // conversationId: JSON.parse(localStorage.getItem("engd_Cust"))
            //   .convId,
            conversationId: checklocalStorage("engd_Cust", "convId", history),
            meetingId: checklocalStorage("meetingId", null, history) || "",
            meetingUrl: meetURL,
            company: checklocalStorage("companyConfig", "id", history) || "",
            agentId: checklocalStorage("id", null, history) || "",
            agentName: checklocalStorage("userName", null, history) || "",
            attendeesList: attendeesList,
          };

          console.log("body ->", data);
          let body = JSON.stringify(data);

          sendInviteConference(body, history)
            .then((res) => {
              if (res.data.error) {
                UTILS.displayErrorList("error", res.data.errorList);
              } else if (res.data.warning) {
                UTILS.displayErrorList("error", res.data.warningList);
              } else {
                openNotificationWithIcon("success", "Invite Send Successfully");
              }
            })
            .catch((error) => {
              console.error("error::", error);
              openNotificationWithIcon("error", "Error in Invite Send.");
            });

          setpstnShow(false);
          setSmsoremail("");
          document.getElementById("smsoremailId").value = "";
        } else {
          setErrorStatus("error");
          if (selValue == "email")
            openNotificationWithIcon("error", "Please enter valid email");
          else openNotificationWithIcon("error", "Please enter valid phone no");
        }
      });
    }
  };

  const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  function sendurlSms(number) {
    //var  toNumber=document.getElementById('callNumber').value;
    var toNumber = number;
    var message =
      "Please click on following url to join call : " +
      agentUidUrl +
      "OR" +
      "Dial 7325620200 extension 252 then conference number 1234 followed by #  to join by phone";
    if (message.length > 0) {
      sendMessage("sms", toNumber, message);
      sendMessage("whatsapp", toNumber, message);
      // document.getElementById('smsoremailId').value='';
    }
    setpstnShow(false);
    setSmsoremail("");
  }

  function sendMessage(msgType, toNumber, message) {
    var To = "+" + toNumber; //	'+17325434406';
    var From;
    var twilio_whatsup = "+14155238886";
    var twilio_sms = "+12056969969";
    if (msgType == "whatsapp") {
      To = "whatsapp:" + To;
      From = "whatsapp:" + twilio_whatsup;
      //var showtime=createShowTime();
      //  addWhatsapp(message,showtime);
    } else {
      From = twilio_sms;
      // addSms(message,showtime)	;
    }
    var urls =
      "https://cors-anywhere.herokuapp.com/http://dev.thebanknet.com:5667/api/v1/sendSms";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        dataType: "text",
      },
      body: JSON.stringify({ to: To, from: From, message: message }),
    };
    fetch(urls, requestOptions)
      .then(async (response) => {
        const data = await response.text();

        //setSmsStatus('success');
      })
      .catch((error) => {
        // setSmsStatus('failed');
        console.error("There was an error!", error);
      });
  }

  function inviteWithEmail(email) {
    let bodyData = JSON.stringify({
      sendTo: email,
      type: "MEETINGINVITE",
      meetUrl: meetURL,
      password: checklocalStorage("roomName", null, history),
    });

    sendInviteMail(bodyData, history)
      .then((res) => {
        const data = res.data;
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <Popover
      visible={pstnShow}
      //onCancel={() => setpstnShow(false)}
      content={
        <div className="pstn-popover">
          <div className="pstn-popover__title">Add User</div>
          <div className="pstn-popover__dropdown">
            <Select
              placeholder="Select Mode"
              value={selValue}
              onChange={smsEmailHandleChange}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <option value="sms">SMS</option>
              <option value="email">Email</option>
            </Select>
          </div>
          <div className="pstn-popover__input">
            <Input
              onChange={handleChange}
              value={smsoremail}
              id="smsoremailId"
              placeholder={
                selValue == "email"
                  ? "Enter email ID's separated by comma (,)"
                  : "+xx xxxxxxxxxx separated by comma (,)"
              }
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div className="pstn-popover__send--btn">
              <Button type="text" onClick={() => callEmailFunction()}>
                {selValue == "email" ? "Send Email" : "Send SMS"}
              </Button>
            </div>
            <div className="pstn-popover__send--btn">
              <Button type="text" onClick={() => setpstnShow(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      }
      trigger="click"
      placement="bottom"
      onVisibleChange={() => setpstnShow(!pstnShow)}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Tooltip title={"Invite Users"}>
        <a href="#" className="image-holder">
          <BsFillPersonPlusFill
            color="white"
            size={24}
            className="control-image"
          />{" "}
        </a>
      </Tooltip>
    </Popover>
  );
}

export default PSTNMenu;
