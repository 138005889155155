// import { createBrowserHistory } from "history";
import axios from "axios";
import { data } from "jquery";
import {
  AUTHENTICATION_SERVICE,
  ADMIN_SERVICE,
} from "../Enviornment/enviornment.js";
import URL_Properties from "../Enviornment/url.js";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { apiRequest,checklocalStorage } from "../util/APIUtility.js";
import { useHistory } from "react-router-dom";
// const history = createBrowserHistory();
async function token() {
  var data = "";
  data = await AsyncLocalStorage.getItem("authToken");
  return data;
}

export const loginUser = async (data) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.SIGN_IN}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await axios(config);
};
export const loginUserConfirm = async (data) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.SIGN_IN_CONFIRMATION}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await axios(config);
};

export const logOut = async (data) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.SIGN_OUT}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${bearerToken}`,
    },
    data: data,
  };
  return await axios(config);
};

export const addUser = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.SIGN_UP}`;
  var data = data;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token()}`,
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const getEnquiry = async (data, history) => {
  var urls = `${ADMIN_SERVICE}company/enquiry`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};
export const fetchPartnerList = async(history) => {
  console.log("fetching entity",checklocalStorage("companyConfig", "entity", history));
  const entitytype = checklocalStorage("companyConfig", "entity", history)
  console.log("entity type", entitytype);
  var urls = `${AUTHENTICATION_SERVICE}api/Company/type/partner`;
    var config = {
      method: "GET",
      url: urls,
      headers: {
        accept: "*/*",
        //'token': await token(),
        "Content-Type": "application/json",
      },
    };
    return await apiRequest("GET", urls, null, null, null, history)
}

export const activateUser = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.ACTIVATE_USER}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  // No need call relogin from apiRequest
  // return await apiRequest("POST", urls, data, null, null, history);
  return await axios(config);
};

export const forgotPassword = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}Auth/ResetPassword`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  // No need call relogin from apiRequest
  // return await apiRequest("POST", urls, data, null, null, history);
  return await axios(config);
};

export const resetPassword = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}Auth/password/update`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  // No need call /relogin
  // return await apiRequest("POST", urls, data, null, null, history);
  return await axios(config);
};

export const updateUser = async (data,history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/User`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token()}`,
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("PUT", urls, data, null, null, history);
};

export const userStatusChange = async (status, userId,history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/v1/User/${userId}/status/${status}`;
  var config = {
    method: "PUT",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token()}`,
    },
    // data: { status: status },
  };
  // return await axios(config);
  return await apiRequest("PUT", urls, null, null, null, history);
};

export const companyUsers = async (companyId,history) => {
  var urls = `${AUTHENTICATION_SERVICE}Users/company/${companyId}`;
  var config = {
    method: "GET",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token()}`,
    },
    // data: { status: status },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};
export const userNotificationStatusChange = async (status, userId,history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/v1/user/${userId}/notify/${status}`;
  var config = {
    method: "PUT",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token()}`,
    },
    // data: { status: status },
  };
  // return await axios(config);
  return await apiRequest("PUT", urls, null, null, null, history);
};

// export const isAuthenticated = async () => {
//   let data
//   try {
//     data = await AsyncLocalStorage.getItem('authToken')
//   } catch(e) {
//     console.log(e)
//   }
//   console.log(data)
//   return data
// };

// Account Api's
export const getCompany = async (companyId,history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/Company/${companyId}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token()}`,
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const setCompanyLogo = async (file, companyId, history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/company/logo`;

  const data = new FormData();
  data.append("file", file);
  data.append("companyId", companyId);

  console.log(data);
  var config = {
    method: "post",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token()}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const updateCompany = async (data,history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/Company`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("PUT", urls, data, null, null, history);
  // return await axios(config);
};

//for adding service provider for a company
export const addServiceProvider = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/Company/ServiceProvider`;
  var config = {
    method: "patch",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("PATCH", urls, data, null, null, history);
  // return await axios(config);
};
