import React from "react";
import { Form, Input, Row, Col, Button } from "antd";
import { DialogueViewLogo } from "../../images";

const AuthenticateUser = () => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
      };
  return (
    <div className="form-wrapper">
      <div className="form-wrapper__content">
        <div className="logo-wrapper">
          <img src={DialogueViewLogo} alt="Logo" />
        </div>
        <h2 className="form-h2">Agent bot ID</h2>
        <div className="signup-form">
          <Form
            form={form}
            name="authentication"
            layout="horizontal"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row className="custom-row">
              <Col xs={24} sm={24}>
                <Form.Item
                  name="Authentication"
                  rules={[
                    {
                      required: true,
                      message: "Please enter agent bot ID",
                    },
                  ]}
                >
                  <Input placeholder="Enter agent bot ID" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item >
              <Button type="primary" htmlType="submit" className="create-account">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>

  );
};

export default AuthenticateUser;
