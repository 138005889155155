import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { DialogueViewLogo } from "../../images/index";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const Logout = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // clear jisti calls
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        realTimeCallStarted: false,
      },
    });

    // conference update
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        conferenceStarted: false,
      },
    });
  }, []);

  return (
    <div className="logout-page">
      <Container fluid className="p-0">
        <Row className="m-0 p-0 login_bg justify-content-md-center">
          <Col lg="6" off className="p-md-5 form_login pb-0">
            <div className="logout-logo">
              <img size="sm" src={DialogueViewLogo} width="193" />
            </div>
            <h6>You have successfully logged out of dialogview application.</h6>
            <div className="login-navigation">
              Do you want to login again? <Link to="/login">Login</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Logout;
