import { createBrowserHistory } from "history";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import axios from "axios";
import { AUTHENTICATION_SERVICE } from "../Enviornment/enviornment.js";
import { log } from "react-strophe/src/bosh.js";
import { logOut } from "../actions/auth.js";
import { UTILS } from "./CommonUtilComponent.js";

const history = createBrowserHistory();

export const userId = async () => {
  var data = "";
  data = await AsyncLocalStorage.getItem("id");
  return data;
};

export const checkAgentsDetails = async () => {
  console.log("APIUTILITY USERS");
  var urls = `${AUTHENTICATION_SERVICE}api/Users`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
    },
    data: [await userId()],
  };
  return await axios(config);
};

async function token() {
  var data = "";
  data = await AsyncLocalStorage.getItem("authToken");
  return data;
}
export const refreshToken = async () => {
  var data = "";
  data = await AsyncLocalStorage.getItem("refreshToken");
  return data;
};

export const signOut = () => {
  console.log("SIGNOUT API UTILITY");
  var data = JSON.stringify({
    authToken: localStorage.getItem("authToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    userId: localStorage.getItem("id"),
  });
  var bearerToken = localStorage.getItem("authToken");
  console.log("DAta:", data);
  console.log("beared token:", bearerToken);
  signOutUser(data, bearerToken);
};

const signOutUser = (data, bearerToken) => {
  try {
    console.log("header=====================================================");
    logOut(data, bearerToken)
      .then((res) => {
        localStorage.removeItem("user");
        localStorage.removeItem("auth");
        localStorage.removeItem("userName");
        localStorage.removeItem("userKey");
        localStorage.removeItem("id");
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("customerId");
        localStorage.removeItem("jitsiMeetId");
        localStorage.removeItem("callStatsUserName");
        localStorage.removeItem("currCusList");
        localStorage.removeItem("currMsgList");
        localStorage.removeItem("role");
        localStorage.removeItem("convID");
        localStorage.removeItem("customerName");
        localStorage.removeItem("company");
        localStorage.removeItem("companyConfig");
        localStorage.removeItem("sideBarSelection");
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};

const signOutWithoutLogoutApi = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("auth");
  localStorage.removeItem("userName");
  localStorage.removeItem("userKey");
  localStorage.removeItem("id");
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("customerId");
  localStorage.removeItem("jitsiMeetId");
  localStorage.removeItem("callStatsUserName");
  localStorage.removeItem("currCusList");
  localStorage.removeItem("currMsgList");
  localStorage.removeItem("role");
  localStorage.removeItem("convID");
  localStorage.removeItem("customerName");
  localStorage.removeItem("company");
  localStorage.removeItem("companyConfig");
  localStorage.removeItem("sideBarSelection");
};

export const apiRequest = async (
  method = "POST",
  URL,
  bodyContent,
  dataType = "Json",
  customHeaders = {},
  history
) => {
  console.log("URL:", URL);
  try {
    //calling API Relogin
    let reloginUrl = `${AUTHENTICATION_SERVICE}auth/relogin/user/${await userId()}`;
    // console.log("refresh token", await refreshToken());
    let reloginConfig = {
      method: "post",
      url: reloginUrl,
      headers: {
        "Content-Type": "text/plain",
      },
      data: await refreshToken(),
    };
    return axios(reloginConfig)
      .then(async (result) => {
        // console.log("relogin..........................", result);
        if (result.data != null && result.data.data != null) {
          if (
            result.data.data.access_token != null &&
            result.data.data.refresh_token != null
          ) {
            console.log(URL, "true");
            localStorage.setItem("authToken", result.data.data.access_token);
            localStorage.setItem(
              "refreshToken",
              result.data.data.refresh_token
            );
          } else {
            console.log(URL, "API_UTILITY - false - expire");
            signOutWithoutLogoutApi();
            history.push("/logout");
            return null;
          }
        } else {
          console.log(
            "API_UTILITY - Unauthorized! Logging out user as data is null."
          );
          signOutWithoutLogoutApi();
          history.push("/logout");
          return null;
        }

        let Body = null;
        if (bodyContent) {
          console.log("bodyContent", bodyContent);
          Body = bodyContent;
        }
        const requestOptions = {
          url: URL,
          method: method,
          headers: {
            // token: await token(),
            "Content-Type": "application/json; charset=utf-8",
            dataType: dataType,
            ...customHeaders,
            Authorization: `Bearer ${await token()}`,
          },
          data: Body,
        };
        return await axios(requestOptions)
          .then((response) => {
            return UTILS.HandleAPIResponse(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              console.log("API_UTILITY - Unauthorized! Logging out user.");
              signOutWithoutLogoutApi();
              history.push("/logout");
              return null;
            }
            console.log("API_UTILITY - API-ERROR: " + error.message);
            return error;
          });
      })
      .catch((error) => {
        // console.log("error:", error);
        // console.log("error response:", error.response);
        // console.log("error status:",error.response.status);
        if (error && error.response.status == 400 && error.response) {
          console.log("API_UTILITY-Unauthorized! Logging out user.");
          signOutWithoutLogoutApi();
          history.push("/logout");
          return null;
        }
        console.log("API_UTILITY-API-ERROR: " + error.message);
        return error;
      });
  } catch (error) {
    console.log("API_UTILITY-API-CATCH-BLOCK::", error);
    return error;
  }
};

export const checklocalStorage = (parentObject, propertyPath, history) => {
  console.log("parentObject ::", parentObject, "propertyPath::", propertyPath);
  if (localStorage.getItem(parentObject)) {
    // console.log("got the value in local Storage");
    parentObject = localStorage.getItem(parentObject);
    try {
      //json structure as value in local storage
      let result = JSON.parse(parentObject);
      // console.log("result:", result);
      //the key has a json value in LS
      if (propertyPath) {
        if (propertyPath.includes(".")) {
          //the key has a json nested value in LS
          const properties = propertyPath.split(".");
          for (const property of properties) {
            console.log("property", property, result.hasOwnProperty(property));
            if (result && result.hasOwnProperty(property)) {
              result = result[property];
              console.log("got it ", result);
            } else {
              console.log("got null");
              // history.push("/logout");
              return null;
            }
          }
          return result;
        } else {
          //the key has a json non-nested value in LS
          if (result && result.hasOwnProperty(propertyPath)) {
            result = result[propertyPath];
            console.log("got it ", result);
          } else {
            console.log("got null");
            // history.push("/logout");
            return null;
          }
          return result;
        }
      } else {
        console.log("just a  value for the key in localStorage", result);
        return result;
      }
    } catch (error) {
      //just a string value for the key in localstorage
      console.log(
        "just a string value for the key in localstorage",
        parentObject
      );
      return parentObject;
    }
  } else {
    console.log("got null");
    // history.push("/logout");
    return null;
  }
};

export const checkIfSessionPresent = async (history) => {
  try {
    //calling API Re login

    const userId = localStorage.getItem("id");
    const refreshToken = localStorage.getItem("refreshToken");

    if (userId && refreshToken) {
      let reloginUrl = `${AUTHENTICATION_SERVICE}auth/relogin/user/${await userId()}`;
      console.log("refresh token", await refreshToken());
      let reloginConfig = {
        method: "post",
        url: reloginUrl,
        headers: {
          "Content-Type": "application/text",
        },
        data: await refreshToken(),
      };
      return axios(reloginConfig).then(async (result) => {
        console.log("relogin..........................", result);
        if (result.data != null && result.data.data != null) {
          console.log(result.data.data);
          if (
            result.data.data.access_token != null &&
            result.data.data.refresh_token != null
          ) {
            localStorage.setItem("authToken", result.data.data.access_token);
            localStorage.setItem(
              "refreshToken",
              result.data.data.refresh_token
            );
            return true;
          } else {
            signOutWithoutLogoutApi();
            history.push("/logout");
            return false;
          }
        } else {
          console.log("Unauthorized! Logging out user as data is null.");
          signOutWithoutLogoutApi();
          history.push("/logout");
          return false;
        }
      });
    } else {
      return false;
    }
  } catch (error) {
    log.error("Error ::", error);
    return false;
  }
};
