import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../pages/conversation/Login";
import Logout from "../pages/conversation/Logout";
import WrapperComponent from "../UI/index";
import RegistrationForm from "../components/SignUpForm/signUpComponent";
import AuthenticateUser from "../components/AuthenticateUser/authenticateUser";
import ActivateUser from "../components/ActivateUser";
import VerifyEmail from "../components/VerifyEmail";
import SignInForm from "../components/SignInForm/signinform";
import DialogviewForget from "../components/Dialogview-forgot/dialogview-forget";

const Routes = () => {
  !localStorage.getItem("sideBarSelection") &&
    localStorage.setItem("sideBarSelection", 2);
  localStorage.setItem("display_chat", false);
  const LoginContainer = () => (
    <div className="container">
      <Login />
    </div>
  );
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={LoginContainer} />
        <Route path="/forgot-password" exact component={DialogviewForget} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/register/:enquiryId" exact component={RegistrationForm} />
        <Route path="/authentication" exact component={AuthenticateUser} />
        <Route path="/enquiry" exact component={RegistrationForm} />
        <Route path="/dialogview-signin" exact component={SignInForm} />
        <Route path="/verify-email" exact component={VerifyEmail} />
        <Route path="/thanks-for-connecting" exact component={VerifyEmail} />
        <Route path="/verify-password" exact component={VerifyEmail} />
        <Route
          path="/personal-info/:userId/email/:email"
          exact
          component={ActivateUser}
        />
        <Route
          path="/reset-password/userName/:userName/token/:token"
          exact
          component={ActivateUser}
        />
        {/* <Route path="/new-conversation" exact component={NewConversation} />        */}
        <ProtectedRoute component={WrapperComponent} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
