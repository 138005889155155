export const remindReducer = (state = [], action) => {
    switch (action.type) {
      case "GET_REMINDER":
        return action.payload;
      default:
        return state;
    }
  };

export const reminderModalReducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_REMINDER_MODAL":
      return action.payload;
    default:
      return state;
  }
};

export const chatEndPopupReducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_CHAT_END_MODAL":
      return action.payload;
    default:
      return state;
  }
};