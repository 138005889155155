let user = localStorage.getItem('id');
let token = localStorage.getItem('authToken');
const initialState = user ? {user: user, token: token}: null

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGGED_IN_USER":
      return action.payload;
    case "LOGOUT":
      return action.payload;
    default:
      return state;
  }
};
