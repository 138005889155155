export const chatReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_CHATLIST":
      return action.payload;
    default:
      return state;
  }
};

export const chatLoadingReducer = (
  state = {
    chatLoading: false,
    chatListLoading: false,
  },
  action
) => {
  switch (action.type) {
    case "LOADING_INDICATOR":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
