import React, { useEffect, useState } from "react";
import { Button, Tooltip, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./real-time-call-window.scss";
import Draggable from "react-draggable";
import { useHistory } from "react-router-dom";
import {
  FaWindowMinimize,
  FaWindowMaximize,
  FaWindowClose,
} from "react-icons/fa";

import { TbMessage2Down } from "react-icons/tb";

import {
  sendChatEvent,
  makeAgentEngaged,
  getAgents,
  sendAgentChatMessage,
} from "../../actions/conversations";

import { Resizable, ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Swal from "sweetalert2";
import { UTILS } from "../../util/CommonUtilComponent.js";
import { checklocalStorage } from "../../util/APIUtility";
import PSTNMenu from "../DropdownPSTN/dropdownPSTN.js";

const RealTimeCallWindow = ({ title, children }) => {
  let dispatch = useDispatch();
  const history = useHistory();

  const {
    conferenceData,
    agentInfo,
    engagedCustomer,
  } = useSelector((state) => ({ ...state }));
  const {
    realTimeCallStarted,
    realTimeData,
    callType,
    isAgentToAgentCall,
  } = conferenceData;
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth - 200,
    height: window.innerHeight - 100,
  });
  const [position, setPosition] = useState({ x: 100, y: -250 });

  useEffect(() => {
    //make agent busy
    updateUserState(true, "engaged");

    startJitsiCall(callType || "audio");

    // Attach the resize event listener
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleMinimize = () => {
    setIsMinimized(true);
    setPosition({ x: 100, y: window.innerHeight - 500 }); // Adjust the values as needed
    setWindowSize({ height: 200, width: 300 });
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setPosition({ x: 100, y: -250 });
    setWindowSize({
      width: window.innerWidth - 200,
      height: window.innerHeight - 100,
    });
  };

  const handleExpand = () => {
    setIsMaximized(!isMaximized);
  };
  const handleGoToMessage = () => {
    // minimize window
    handleMinimize();
    // focus to input
    let InputBox = document.getElementById("chatSend");
    if (InputBox) InputBox.focus();

    // open conversation page if user in other page
    let side_menu_conversation = document.getElementById(
      "side-menu-conversation"
    );
    if (side_menu_conversation) {
      // check if user in other page
      if (side_menu_conversation.classList.contains("ant-menu-item-selected")) {
      } else {
        // open conversation page and open chat panel
        history.push("/");
        side_menu_conversation.click();

        setTimeout(() => {
          // now open chat panel
          let engaged_customer_ele = document.getElementById(
            engagedCustomer.id
          );
          if (engaged_customer_ele) {
            // alert("customer found  calling onclick..!");
            engaged_customer_ele.click();
            setTimeout(() => {
              let InputBox = document.getElementById("chatSend");
              if (InputBox) InputBox.focus();
            }, 2000);
          }
        }, 2000);
      }
    }
  };

  const cancelPopupButton = (
    <>
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close("callWarning");
          dispatch({
            type: "CONFERENCE_UPDATE",
            payload: {
              realTimeCallStarted: false,
            },
          });

          // update agent status to online
          updateUserState(false, "online");

          // Sending  end call event
          if (isAgentToAgentCall) {
            const agentConversationId = realTimeData?.agentConversationId;
            if (agentConversationId) {
              const callEvent = {
                conversationId: agentConversationId,
                messagebody: "CALL_ENDED",
                sentBy: agentInfo.id,
                type: "ND",
              };
              sendAgentChatMessage(callEvent, history);
            }
            // remove call icon
            showHideCallIcon(false);
          } else {
            // send end call event
            var msgObj = {
              conversationId: JSON.parse(localStorage.getItem("engd_Cust"))
                .convId,
              messagebody: `CALL_ENDED`,
              sentBy: "AGENT",
              type: "JITSI",
            };
            sendChatEvent(msgObj, history);
          }
        }}
      >
        Confirm
      </Button>
      &nbsp;
      <Button size="small" onClick={() => notification.close("callWarning")}>
        Cancel
      </Button>
    </>
  );

  const handleClose = () => {
    // if yes update conference status to false and close the window
    // Swal.fire({
    //   title: "You will be removed from the Call",
    //   // text: "Do you want to continue",
    //   icon: "question",
    //   confirmButtonText: "ok",
    //   showCancelButton: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     dispatch({
    //       type: "CONFERENCE_UPDATE",
    //       payload: {
    //         realTimeCallStarted: false,
    //       },
    //     });

    //     // update agent status to online
    //     onLoadJitsiCall(false, "online");
    //   }

    //   // send end call event
    //   var msgObj = {
    //     conversationId: JSON.parse(localStorage.getItem("engd_Cust")).convId,
    //     messagebody: `CALL_ENDED`,
    //     sentBy: "AGENT",
    //     type: "JITSI",
    //   };
    //   sendChatEvent(msgObj, history);
    // });

    UTILS.CustomCloseNotification(
      "info",
      "You will be removed from the Call !",
      cancelPopupButton,
      "callWarning"
    );
  };

  const handleResize = (event, { element, size }) => {
    // Handle resize action here
    // The 'size' parameter contains the new width and height after resizing
    // You can access size.width and size.height to update your component's state
    // console.log("New size:", size);
    setWindowSize(size);
  };

  const handleWindowResize = () => {
    // Adjust the position based on zoom level and window dimensions
    const adjustedX = position.x * (window.innerWidth / window.outerWidth);
    const adjustedY = position.y * (window.innerHeight / window.outerHeight);
    setPosition({ x: adjustedX, y: adjustedY });
  };

  const handleDrag = (e, data) => {
    const { clientX, clientY } = e;

    console.log("e ->", e, "data -> ", data);
    const { offsetWidth, offsetHeight } = data.node;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const maxX = windowWidth - offsetWidth;
    const maxY = windowHeight - offsetHeight;

    const x = Math.min(Math.max(0, clientX - data.x), maxX);
    const y = Math.min(Math.max(0, clientY - data.y), maxY);

    setPosition({
      x: data.x,
      y: data.y,
    }); // Update position while dragging
  };

  const startJitsiCall = async (forAudioOrVideo = "audio") => {
    console.log("Starting Real Time >>>>>>>>>>>>>>>>>>");

    const roomName = realTimeData.meetingId
      ? realTimeData.meetingId
      : checklocalStorage("meetingId", null, history);
    const agentName =
      `${agentInfo.firstName} ${agentInfo.lastName}` ||
      checklocalStorage("userName", null, history) ||
      `Agent-${new Date().getTime().toString().slice(-6)}`;

    // dispatch({
    //   type: "JITSI_UPDATE",
    //   payload: {
    //     mediaType: "video",
    //     meet: true,
    //     outgoing: false,
    //     accepted: true,
    //   },
    // });

    const jitsiContainerId = "real-time-video-container";
    const loadJitsiScript = () => {
      let resolveLoadJitsiScriptPromise = null;

      const loadJitsiScriptPromise = new Promise((resolve) => {
        resolveLoadJitsiScriptPromise = resolve;
      });

      const script = document.createElement("script");
      script.src = "https://meet-qa.dialogview.ai/external_api.js";
      script.async = true;
      script.onload = () => resolveLoadJitsiScriptPromise(true);
      document.body.appendChild(script);

      return loadJitsiScriptPromise;
    };

    if (!window.JitsiMeetExternalAPI) {
      dispatch({
        type: "JITSI_UPDATE",
        payload: {
          load: true,
        },
      });
      await loadJitsiScript();
    }

    console.log("videoForAudio -->", forAudioOrVideo);

    const videoOptions = {
      roomName: roomName,
      parentNode: document.getElementById(jitsiContainerId),
      userInfo: {
        displayName: agentName,
      },
      configOverwrite: {
        doNotStoreRoom: true,
        // startVideoMuted: 4,
        startWithVideoMuted: forAudioOrVideo == "video" ? false : true,
        // startAudioOnly: false,
        startWithAudioMuted: false,
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        disableRemoteMute: true,
        remoteVideoMenu: {
          disableKick: true,
        },
        disableDeepLinking: true,
        disableSelfViewSettings: true,
      },
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        FILM_STRIP_MAX_HEIGHT: 120,
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        DEFAULT_REMOTE_DISPLAY_NAME: "New User",
        BRAND_WATERMARK_LINK: "https://dialogview.com/",
        TOOLBAR_BUTTONS: [
          "fullscreen",
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          // "embedmeeting",
          "fodeviceselection",
          "hangup",
          // "profile",
          // "chat",
          "recording",
          "livestreaming",
          // "etherpad",
          // "sharedvideo",
          // "shareaudio",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          "feedback",
          // "stats",
          "shortcuts",
          "tileview",
          "select-background",
          "download",
          "help",
          "mute-everyone",
          "mute-video-everyone",
          // "security",
          "whiteboard",
        ],
      },
      onload: function () {
        dispatch({
          type: "JITSI_UPDATE",
          payload: {
            load: false,
          },
        });

        // do action on load
        // onLoadJitsiCall(true, "engaged");
      },
    };

    const apiObj = new window.JitsiMeetExternalAPI(
      "meet-qa.dialogview.ai",
      videoOptions
    );

    apiObj.addEventListeners({
      readyToClose: () => {
        // Sending  end call event
        if (isAgentToAgentCall) {
          const agentConversationId = realTimeData?.agentConversationId;
          if (agentConversationId) {
            const callEvent = {
              conversationId: agentConversationId,
              messagebody: "CALL_ENDED",
              sentBy: agentInfo.id,
              type: "ND",
            };
            sendAgentChatMessage(callEvent, history);
          }
          // remove call icon
          showHideCallIcon(false);
        } else {
          const msgObj = {
            // conversationId: JSON.parse(localStorage.getItem("engd_Cust")).convId,
            conversationId: checklocalStorage("engd_Cust", "convId", history),
            messagebody: `CALL_ENDED`,
            sentBy: "AGENT",
            type: "JITSI",
          };
          sendChatEvent(msgObj, history);
        }

        // update agent status to online
        updateUserState(false, "online");

        // Closing the window
        dispatch({
          type: "CONFERENCE_UPDATE",
          payload: {
            realTimeCallStarted: false,
            callEngagedCustomer: {},
            isAgentToAgentCall: false,
          },
        });
      },
      filmstripDisplayChanged: function (event) {
        console.log("filmstrip display changed-->", event);
        if (!event.visible) {
          // apiObj.executeCommand("toggleFilmStrip");
        }
      },
      videoMuteStatusChanged: function (event) {
        console.log("videoMuteStatusChanged -->", event);
        if (event.muted) {
          console.log("videoMuteStatusChanged MUTED", event);
          // setVideoMute(true);
        } else {
          console.log("videoMuteStatusChanged FALSE", event);
          // setVideoMute(false);
        }
      },
      audioMuteStatusChanged: function (event) {
        console.log("audioMuteStatusChanged -->", event);
        if (event.muted) {
          // setAudioMute(true);
        } else {
          // setAudioMute(false);
          // enable noise suppression
          setTimeout(() => {
            apiObj.executeCommand("setNoiseSuppressionEnabled", {
              enabled: true, // Enable or disable noise suppression.
            });
          }, 2000);
        }
      },
    });

    apiObj.executeCommand("subject", roomName);
    apiObj.executeCommand("toggleFilmStrip");
  };

  const updateUserState = (IS_ENGAGED = false, STATUS = "online") => {
    dispatch({
      type: "IS_ENGAGED",
      payload: IS_ENGAGED,
    });

    console.log("updateUserState ->", agentInfo);
    // make agent STATUS to engaged
    let agentInfoBody = {
      ...agentInfo,
      status: STATUS,
    };
    dispatch({
      type: "ADD_USERDATA",
      payload: agentInfoBody,
    });
    console.log("updateUserState after update ->", agentInfo);

    // adding engaged details to conference state
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        callEngagedCustomer: STATUS == "online" ? {} : engagedCustomer,
      },
    });

    // make agent status to engaged
    makeAgentEngaged(STATUS, history)
      .then((res) => {
        if (res && res.data && res.data.data) {
          console.log(res.data.data);

          //refresh the agent list
          fetchAgentList();
        }
      })
      .catch((err) => {
        UTILS.Notification("error", "Error while adding agent to chat");
        console.log(err);
      });
  };

  const showHideCallIcon = (showHide = false) => {
    const updatedOnlineAgentList = checklocalStorage(
      "online_agents",
      null,
      history
    );
    const agentConversationId = realTimeData?.agentConversationId;
    if (agentConversationId) {
      const agent_id = localStorage.getItem("id");
      updatedOnlineAgentList.forEach(function (item, i) {
        if (item.id === agentConversationId) {
          item.callEvent[agent_id] = showHide;
          updatedOnlineAgentList.splice(i, 1);
          updatedOnlineAgentList.unshift(item);
        }
      });
      dispatch({
        type: "AGENT_QUEUE_LIST",
        payload: updatedOnlineAgentList,
      });
    }
  };

  const fetchAgentList = () => {
    getAgents(history)
      .then((res) => {
        const data = res.data.data;
        if (null != data && data.length >= 0) {
          dispatch({
            type: "GET_AGENTSLIST",
            payload: data,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div>
      {realTimeCallStarted && (
        <Draggable
          onDrag={handleDrag}
          handle=".window-title"
          defaultPosition={{ x: 10, y: 10 }}
          position={position}
          // grid={[25, 25]}
          scale={1}
          bounds="body"
        >
          <div
            className="resizable-window"
            style={{ height: windowSize.height, width: windowSize.width }}
          >
            <div className="window-title">
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Tooltip title={title}>{title}</Tooltip>
              </div>

              <div className="window-actions">
                {!isAgentToAgentCall && <PSTNMenu />}
                <Tooltip title={"Go Back to Chat"}>
                  <TbMessage2Down
                    size={20}
                    onClick={handleGoToMessage}
                    className="window-action"
                  />
                </Tooltip>
                <FaWindowMinimize
                  onClick={handleMinimize}
                  className="window-action"
                />
                <FaWindowMaximize
                  onClick={handleMaximize}
                  className="window-action"
                />
                <FaWindowClose
                  onClick={handleClose}
                  className="window-action"
                />
              </div>
            </div>
            {/* <div className="window-content">{children}</div>
            <div className="resize-handle" onMouseDown={handleResize} /> */}

            <div
              className="window-content"
              // style={{ height: windowSize.height, width: windowSize.width }}
            >
              {/* Window content goes here */}
              <div
                id="real-time-video-container"
                style={{
                  height: `${windowSize.height - 60}px`,
                  width: "100%",
                  transition: "all 0.2s ease-in-out",
                }}
              />
            </div>
            {/* 
            <Resizable
              width={windowSize.width}
              height={windowSize.height}
              minConstraints={[300, 200]}
              maxConstraints={[window.innerWidth, window.innerHeight]}
              onResize={handleResize}
            >
              <div className="resize-handle" />
            </Resizable> */}
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default RealTimeCallWindow;
