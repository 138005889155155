import { Form, Input, Button, Checkbox } from "antd";
import { DialogueViewLogo } from "../../images";
import { Link } from 'react-router-dom';

const SignInForm = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="form-wrapper">
        <div className="form-wrapper__content">
          <div className="logo-wrapper">
            <img src={DialogueViewLogo} alt="Logo" />
          </div>
          <h2 className="form-h2">Sign in</h2>
          <div className="signin-form signup-form">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: "Please input your username!" }]}
              >
                <Input placeholder="Username" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item >
                <div className="forgot-navigation">
                  <Button type="primary" htmlType="submit" className="create-account">
                    Sign in
                  </Button>
                  <Link to="/dialogview-forgot" className="forgot-btn">Forgot password?</Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
