import { Select, notification } from "antd";
import Swal from "sweetalert2";

const { Option } = Select;

export const UTILS = {
  numberFormat: (value) => {
    if (!value) return value;

    const cleanNum = value.toString().replace(/[^\d]/g, "");
    console.log("cleanNum --> ", cleanNum);

    const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return (
        "(" + match[1] + ") " + (match[2] ? match[2] + "-" : "") + match[3]
      );
    }
    return cleanNum;

    // console.log("numberFormat ->", value);

    // // if input value is falsy eg if the user deletes the input, then just return
    // if (!value) return value;

    // // clean the input for any non-digit values.
    // const phoneNumber = value.toString().replace(/[^\d]/g, "");
    // // phoneNumberLength is used to know when to apply our formatting for the phone number
    // const phoneNumberLength = phoneNumber.length;

    // if (phoneNumberLength < 3) {
    //   return "+" + `${phoneNumber.slice(0, 3)}`;
    // }
    // if (phoneNumberLength < 6) {
    //   return "+" + `${phoneNumber.slice(0, 3)}(${phoneNumber.slice(3, 6)})`;
    // }

    // if (phoneNumberLength < 9) {
    //   return (
    //     "+" +
    //     `${phoneNumber.slice(0, 2)}(${phoneNumber.slice(
    //       2,
    //       5
    //     )})-${phoneNumber.slice(5, 8)}`
    //   );
    // }

    // if (phoneNumberLength < 12) {
    //   return (
    //     "+" +
    //     `${phoneNumber.slice(0, 2)}(${phoneNumber.slice(
    //       2,
    //       5
    //     )})-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`
    //   );
    // } else {
    //   return (
    //     "+" +
    //     `${phoneNumber.slice(0, 3)}(${phoneNumber.slice(
    //       3,
    //       6
    //     )})-${phoneNumber.slice(6, 9)}-${phoneNumber.slice(9, 13)}`
    //   );
    // }
  },
  isAlphaNumeric: (value) => {
    if (/[A-Za-z0-9]+/.test(value)) {
      return true;
    } else return false;
  },
  isFirstOrLastName: (value) => {
    if (/[A-Za-z]+/gi.test(value)) {
      console.log("value ->", value);
      return true;
    } else return false;
  },
  isUserName: (value) => {
    if (/^[A-Za-z0-9_]+.*$/.test(value)) {
      return true;
    } else return false;
  },
  validateEmail: function (email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else return false;
  },
  validatePhoneNumber: function (phoneNumber) {
    if (/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumber)) {
      return true;
    }
    return false;
  },
  validatePhoneNumberCountryCode: function (phoneNumber) {
    if (
      /^[+]\d{1,3}[- ]?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumber)
    ) {
      console.log("VALID -->", phoneNumber);
      return true;
    }
    return false;
  },
  displayErrorList: (type, errorList) => {
    console.log("errorList -->", errorList);
    errorList &&
      errorList.length > 0 &&
      errorList.forEach((error) => {
        if (error) {
          return notification[type]({
            // message: title,
            description: error.errWrnDescription,
            // placement: position,
          });
        } else {
          return notification[type]({
            message: "Unable to get error details.",
            // placement: position,
          });
        }
      });
  },
  selectWithFilter: function (items = []) {
    return (
      <Select
        showSearch
        style={{
          width: "100%",
        }}
        placeholder="Search to Select"
        optionFilterProp="children"
        filterOption={(input, option) => option.children.includes(input)}
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        <Option value={"india"}>{"india"}</Option>
        {items.map((item, index) => (
          <Option value={item}>{item}</Option>
        ))}
      </Select>
    );
  },
  alert: function () {},
  Notification: function (type, title, description, position) {
    return notification[type]({
      // key: "updatable",
      message: title,
      description: description,
      placement: position,
    });
  },
   CustomCloseNotification: function (type, title, btn,key) {
    return notification[type]({
      message: title,
    btn,
    key,
    });
  },
  SweetAlertToaster: (type, title, description, position) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: type,
      title: title,
    });
  },

  HandleAPIResponse: (response) => {
    if (response) {
      if (response.error) {
        if (response.errorList && response.errorList.length > 0) {
          UTILS.displayErrorList("error", response.errorList);
        } else {
          UTILS.Notification(
            "error",
            "Error Details not available please contact the administrator"
          );
        }
        return false;
      } else if (response.warning) {
        console.log("USR)))(:",response?.warningList[0]?.errWrnCode)
        if (response.warningList && response.warningList.length > 0 ){
          if( response?.warningList[0]?.errWrnCode!=="USROOO9") {
          UTILS.displayErrorList("warning", response.warningList);
          }
          else{
          return false;
          }
        } else {
          UTILS.Notification(
            "error",
            "Error Details not available please contact the administrator"
          );
        }
        return false;
      } else {
        return response;
      }
    } else {
      UTILS.Notification("error", "Server error");
      return false;
    }
  },
};
