export const cannedMsgReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_CANNED_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

export const cannedResponseReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

export const selectedCannedReducer = (state = "", action) => {
  switch (action.type) {
    case "SELECT_CANNED_RESPONSE":
      return action.payload;
    default:
      return state;
  }
};

export const activeAccordionReducer = (state = "", action) => {
  switch (action.type) {
    case "ACTIVE_PANEL_ACCORDION":
      return action.payload;
    default:
      return state;
  }
};
export const activeAgentChatInput = (state = false, action) => {
  switch (action.type) {
    case "ACTIVE_AGENT_CHAT_INPUT":
      return action.payload;
    default:
      return state;
  }
};

export const activeAgentCustomerTab = (state = "customer", action) => {
  switch (action.type) {
    case "ACTIVE_AGENT_CUSTOMER_TAB":
      return action.payload;
    default:
      return state;
  }
};
