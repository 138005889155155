import React, { useState, useEffect } from "react";
import { Button } from "antd";
import "./userAvatar.scss";
import MaleAvatar from "../../images/avatar-male.svg";

function userAvatar({ url, status, userDetails, style }) {
  // console.log("url =", url, "status =", status);
  url = url ? url : MaleAvatar;
  return (
    <>
      <div className="icon-container" style={style ? style : {}}>
        <img src={url} alt="User Profile" />
        <div
          className="status-circle"
          title={status}
          style={
            status == "online"
              ? { backgroundColor: "green" }
              : status == "engaged"
              ? { backgroundColor: "red" }
              : { backgroundColor: "gray" }
          }
        ></div>
      </div>
    </>
  );
}

export default userAvatar;
