export const customerReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_CUSTOMERS":
      localStorage.setItem("online_customers", JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
};

export const isEngagedReducer = (state = false, action) => {
  switch (action.type) {
    case "IS_ENGAGED":
      return action.payload;
    default:
      return state;
  }
};

export const displayChat = (state = false, action) => {
  switch (action.type) {
    case "DISPLAY_CHAT":
      localStorage.setItem("display_chat", JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
};

export const engagedCustomerReducer = (state = {}, action) => {
  console.log("engagedCustomerReducer ==>", action.payload);
  switch (action.type) {
    case "GOTO_ENGAGED":
      localStorage.setItem("engd_Cust", JSON.stringify(action.payload));
      return action.payload;

    default:
      return state;
  }
};

export const webSocketReducer = (
  state = { socketConnected: false },
  action
) => {
  console.log("webSocketReducer ==>", action.payload);
  switch (action.type) {
    case "UPDATE_WEBSOCKET":
      return action.payload;

    default:
      return state;
  }
};
