import {Spin} from "antd";
import "./spinner.scss";
const Spinner = () => {
    return (
        <div className="spinner-wrapper">
            {/* <div className="sp sp-bars">
            </div> */}
            <Spin />
        </div>
        
    );
}

export default Spinner;