import React from "react";
import HeaderComponent from "../components/HeaderComponent/header";
import Sidebar from "../components/SidebarComponent/sidebar";
import ContentArea from "../components/ContentArea/contentArea";
import "./index.scss";
import { useIdleTimer } from "react-idle-timer";
import { logOut } from "../actions/auth";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ConferenceWindow from "../components/ConferenceWindow/ConferenceWindow";
import { checklocalStorage } from "../util/APIUtility";

const WrapperComponent = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  const handleOnIdle = (event) => {
    console.log("user is idle: last active", getLastActiveTime());
    var data = JSON.stringify({
      // authToken: localStorage.getItem("authToken"),
      // refreshToken: localStorage.getItem("refreshToken"),
      // userId: localStorage.getItem("id"),
      authToken: checklocalStorage("authToken",null,history),
      refreshToken: checklocalStorage("refreshToken",null,history),
      userId: checklocalStorage("id",null,history),
    });
    // var bearerToken = localStorage.getItem("authToken");
    var bearerToken = checklocalStorage("authToken",null,history);
    console.log(
      "index=========================================================="
    );

    logOut(data, bearerToken)
      .then((res) => {
        localStorage.removeItem("use");
        localStorage.removeItem("auth");
        localStorage.removeItem("userName");
        localStorage.removeItem("userKey");
        localStorage.removeItem("id");
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("customerId");
        localStorage.removeItem("jitsiMeetId");
        localStorage.removeItem("callStatsUserName");
        localStorage.removeItem("currCusList");
        localStorage.removeItem("currMsgList");
        localStorage.removeItem("role");
        localStorage.removeItem("convID");
        localStorage.removeItem("customerName");
        localStorage.removeItem("company");
        localStorage.removeItem("companyConfig");
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
        dispatch({
          type: "ADD_CUSTOMERS",
          payload: [],
        });
        history.push("/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { getLastActiveTime } = useIdleTimer({
    // timeout: 1000 * 60 * 15,
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <div className="project-wrapper">
      <HeaderComponent />
      <div className="content-wrapper">
        <Sidebar />
        <ContentArea />
      </div>
      {/* <ConferenceWindow /> */}
    </div>
  );
};

export default WrapperComponent;
