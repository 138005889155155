export const userReducer = (state = {}, action) => {
  console.log("UserStore after update ->", state, action.payload);
  switch (action.type) {
    case "ADD_USERDATA":
      localStorage.setItem(
        "agent_info",
        JSON.stringify({ ...state, ...action.payload })
      );
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
