import React, { useState } from "react";
import { Form, Input, Select, Row, Col, Button, AutoComplete } from "antd";
import "./signUpForm.scss";
import { addUser, getEnquiry } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import ContryList from "./countryList.json";
import { DialogueViewLogo } from "../../images";
import { putEnquiryStatus } from "../../actions/conversations";


const { Option } = Select;

const RegistrationForm = (props) => {
  const [form] = Form.useForm();
  let history = useHistory();

  let enquiryID = props.match.params.enquiryId;

  const onFinish = async (values) => {
    const removeSlash = (string) => {
      const lastChar = string.charAt(string.length - 1);
      if (lastChar == "/" ) {
        return string.substring(0, string.length - 1);
      } else return string;
    }
    if( history.location.pathname.includes("/register/")) {
      var registerData = JSON.stringify({
        "contactNumber": values.contactNumber,
        "email": values.email,
        "role": "admin",
        "status": "offline",
        "firstName": values.firstName,
        "lastName": values.lastName,
        "company": values.company.toUpperCase(),
        "baseUrl": window.location.host,
        "integrationSites": [removeSlash(values.website)]
      });
      console.log("Received values of form: ", registerData);
      addUser(registerData,history)
      .then((res) => {
        putEnquiryStatus(enquiryID, "registered")
        .then((res) => { 
          history.push("/verify-email");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
        
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
    } else {
      var enquiryData = JSON.stringify({
        "contactNumber": values.contactNumber,
        "companyEmail": values.email,
        "role": values.role,
        "status": "open",
        "userCount": values.userCount,
        "firstName": values.firstName,
        "lastName": values.lastName,
        "companyWebsite": values.website,
        "companyName": values.company,
      });
      getEnquiry(enquiryData,history)
      .then((res) => {
        console.log(res.data);
        history.push("/thanks-for-connecting");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
    }   
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 120,
          textAlign: 'left',
        }}
      >
        {ContryList.map((country, idx) => {
          return (
            <Option data-countryCode={country.code} value={country.dial_code} key={idx}>
              {country.name} ({country.dial_code})
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  );
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));
  return (
    <div className="form-wrapper">
      {/* <div className={ history.location.pathname.includes("/register/") ? `form-wrapper__content width-changer`: `form-wrapper__content`}> */}
      <div className="form-wrapper__content width-changer">
        <div className="logo-wrapper">
          <img src={DialogueViewLogo} alt="Logo" />
        </div>
        <h2 className="form-h2">{ history.location.pathname.includes("/register/") ? 'Registration' : 'Enquire with us'}</h2>
        <div className="signup-form">
          <Form
            form={form}
            name="register"
            layout="horizontal"
            onFinish={onFinish}
            initialValues={{
              prefix: "India (+91)",
            }}
            scrollToFirstError
          >
            <Row className="custom-row">
              <Col xs={24} sm={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="custom-row">
              <Col xs={24} sm={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                    },
                    {
                      required: true,
                      message: "Please enter company email",
                    },
                  ]}
                >
                  <Input placeholder="Company Email" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>


                <Form.Item
                  name="company"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                >
                  <Input placeholder="Company Name" />
                </Form.Item>
              </Col>
            </Row>
            { 
              history.location.pathname.includes("/register/") ? '' : (
                <Row className="custom-row">
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: "Please enter role",
                        },
                      ]}
                    >
                      <Input placeholder="Role" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="userCount"
                      rules={[
                        {
                          required: true,
                          message: 'Please select no. of users',
                        },
                      ]}
                    >
                      <Select placeholder="No. of Users">
                        <Option value="1-10">1 - 10</Option>
                        <Option value="11-50">11 - 50</Option>
                        <Option value="51-100">51 - 100</Option>
                        <Option value="101-500">101 - 500</Option>
                        <Option value="above 500">Above 500</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            
            <Row className="custom-row">
              <Col xs={24} sm={12}>
                <Form.Item
                  name="website"
                  rules={[
                    {
                      required: true,
                      message: "Please enter URL for integration",
                    },
                  ]}
                >
                  <AutoComplete
                    options={websiteOptions}
                    onChange={onWebsiteChange}
                    placeholder="Company Web URL"
                  >
                    <Input />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company number",
                    },
                  ]}
                >
                  <Input
                    // addonBefore={prefixSelector}
                    placeholder="Company Number"
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Should accept agreement")),
              },
            ]}
            {...tailFormItemLayout}
          >
            <Checkbox>
              I have read the <a href="">agreement</a>
            </Checkbox>
          </Form.Item> */}
            <Form.Item >
              <Button type="primary" htmlType="submit" className="create-account">
                {
                  history.location.pathname.includes("/register/") ? "Signup" : "Get Connected"
                }
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>

  );
};

export default RegistrationForm;
