import { createBrowserHistory } from "history";
import axios from "axios";
import {
  AGENT_SERVICE,
  CHAT_SERVICE,
  AUTHENTICATION_SERVICE,
  ADMIN_SERVICE,
} from "../Enviornment/enviornment.js";
import URL_Properties from "../Enviornment/url.js";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { UTILS } from "../util/CommonUtilComponent.js";
import { apiRequest, checklocalStorage, signOut } from "../util/APIUtility.js";

async function token(history) {
  var data = "";
  data = await checklocalStorage("authToken", null, history);
  return data;
}
async function userId(history) {
  var data = "";
  data = await checklocalStorage("id", null, history);
  console.log("userId::::::::::::::::::::::::::::::::::::::::::::::", data);
  return data;
}
async function userEmail(history) {
  var data = "";
  data = await checklocalStorage("email", null, history);
  return data;
}
async function getCompany(history) {
  var data = "";
  data = await checklocalStorage("companyConfig", "id", history);
  return data;
}
async function getCustomer(history) {
  var data = "";
  // data = JSON.parse(localStorage.getItem("engd_Cust")).customer.id;
  data = checklocalStorage("engd_Cust", "customer.id", history);
  return data;
}
// var userId = localStorage.getItem("id");

export const getTeams = async (history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.FETCH_TEAM_USER}${await userId(
    history
  )}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

//Accounts

export const getAccounts = async (history) => {
  var urls = `${ADMIN_SERVICE}api/v1/accounts`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };

  return await apiRequest("GET", urls, null, null, null, history);
};

export const getAccountsAdminPartner = async (history) => {
  console.log("await getCompanyresellerId(history)", await getCompany(history));
  var urls = `${ADMIN_SERVICE}api/v1/accounts/partner/${await getCompany(
    history
  )}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };

  return await apiRequest("GET", urls, null, null, null, history);
};
export const checkOptIn = async (data, history) => {
  var urls = `${CHAT_SERVICE}api/v1/customeropt/status`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("POST", urls, data, null, null, history);
};
export const accountStatus = async (data, companyData, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/enabled/${data}/company/${companyData}`;

  var config = {
    method: "put",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("PUT", urls, null, null, null, history);
};

//Enquiry

export const getStatusEnquiries = async (companyStatus, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/Enquiry/status/${companyStatus}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

export const putEnquiryStatus = async (companyID, status, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/Enquiry/${companyID}/status/${status}`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("PUT", urls, null, null, null, history);
};

//Survey Rating

export const getHeaderSurveyRating = async (history) => {
  var urls = `${CHAT_SERVICE}api/v1/rating/agent/${await userId(history)}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

// Conversation attended graph

export const getConversationAttended = async (history) => {
  var urls = `${ADMIN_SERVICE}api/v1/dashboard/conversation_attended/${await getCompany(
    history
  )}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

// Conversation/Hour Statistics graph

export const getConversationStatistics = async (history) => {
  var urls = `${CHAT_SERVICE}api/v1/conversation/company/statistics/${await getCompany(
    history
  )}`;
  var data = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "text/plain",
    },
    data: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  return await apiRequest(
    "POST",
    urls,
    JSON.stringify(data),
    null,
    null,
    history
  );
  // return await axios(config);
};

//Offline

export const getOfflineCustomers = async (history) => {
  var urls = `${CHAT_SERVICE}api/v1/offlineconversation/company/${await getCompany(
    history
  )}/attended/false/agent/${await userId(history)}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getOfflineCustomerWithFilter = async (
  pageNumber,
  size,
  history
) => {
  var urls = `${CHAT_SERVICE}api/v1/offlineconversation/company/${await getCompany(
    history
  )}/attended/false/agent/${await userId(history)}`;
  let data = JSON.stringify({
    pageNumber: pageNumber,
    size: size,
  });
  let config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: { pageNumber: pageNumber, size: size },
  };
  return await apiRequest("POST", urls, data, null, null, history);
};

export const endOfflineConversation = async (data, history) => {
  var urls = `${CHAT_SERVICE}conversation/offline/close`;

  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

//Facebook Subscription

export const postFacebookSubscription = async (data, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/subscribe/facebook`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

// Get Facebook Subscription
export const getFacebookSubscription = async (history) => {
  var urls = `${ADMIN_SERVICE}api/v1/subscribe/facebook/company/${await getCompany(
    history
  )}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
  // return await axios(config);
  // .then((res) => {
  //   console.log(res.data);
  //   !res.error &&
  //   UTILS.Notification("success", "Facebook subscription added successfully");
  // // })
  // .catch((error) => {
  //   console.error("There was an error!", error);
  //   UTILS.Notification("error", "Error while adding facebook subscription");
  // });
};

export const getAccountsFacebookSubscription = async (companyId, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/subscribe/facebook/company/${companyId}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

// Delete Facebook Subscription
export const deleteFacebookSubscription = async (id, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/subscribe/facebook/${id}`;
  var config = {
    method: "delete",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("DELETE", urls, null, null, null, history)
    .then((res) => {
      if (res && res.data) {
        console.log(res.data);
        !res.error &&
          UTILS.Notification(
            "success",
            "Facebook subscription deleted successfully"
          );
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      UTILS.Notification("error", "Error while deleting facebook subscription");
    });
};

// Update Facebook Subscription
export const updateFacebookSubscription = async (data, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/subscribe/facebook/`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  // return await axios(config)
  return await apiRequest("PUT", urls, data, null, null, history)
    .then((res) => {
      console.log(res.data);
      !res.error &&
        UTILS.Notification(
          "success",
          "Facebook subscription updated successfully"
        );
    })
    .catch((error) => {
      console.error("There was an error!", error);
      UTILS.Notification("error", "Error while updating facebook subscription");
    });
};

// Project ID Mapping

export const putProjectID = async (data, history) => {
  var urls = `${ADMIN_SERVICE}api/v1/ProjectId`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("PUT", urls, data, null, null, history);
};

export const getDocsAll = async (history) => {
  var urls = `${AGENT_SERVICE}${
    URL_Properties.FETCH_DOCUMENTS_ALL
  }/${await userId(history)}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getDocsUser = async (history) => {
  var urls = `${AGENT_SERVICE}${
    URL_Properties.FETCH_DOCUMENTS_USER
  }${await userId(history)}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

export const addDocUser = async (data, history) => {
  var urls = `${AGENT_SERVICE}api/v1/Document`;

  var config = {
    method: "post",
    url: `${AGENT_SERVICE}api/v1/Document`,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  //  return await axios(config);
};

export const getDocs = async (id, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.FETCH_DOCUMENTS_TEAM}/${id}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const deleteDoc = async (fileUrl, history) => {
  var urls = `${AGENT_SERVICE}api/v1/Document`;

  var data = `${AGENT_SERVICE}api/v1/s3/company/${await getCompany(
    history
  )}/${fileUrl}`;

  var config = {
    method: "delete",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("DELETE", urls, data, null, null, history);
  // return await axios(config);
};

export const getDefaultHistory = async (history) => {
  var cusID = checklocalStorage("customerId", null, history);
  if (cusID !== "" || cusID != null)
    var urls = `${CHAT_SERVICE}${
      URL_Properties.FETCH_CONV_HISTORY_AC
    }${await userId(history)}${
      URL_Properties.FETCH_CONV_HISTORY_CLIENT
    }${cusID}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getCustomerHistory = async (history) => {
  var urls = `${CHAT_SERVICE}api/v1/conversation${
    URL_Properties.FETCH_CONV_HISTORY_CLIENT
  }${await getCustomer(history)}`;
  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getCustomerQueue = async (history) => {
  var urls = `${CHAT_SERVICE}${
    URL_Properties.FETCH_CUSTOMERQUEUE
  }${await getCompany(history)}/agent/${await userId(history)}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const sendInviteMail = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}${URL_Properties.SEND_EMAIL}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const sendInviteConference = async (data, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.SEND_CONFERENCE_INVITE}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const editCustomerQueue = async (id, data, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.UPDATE_CUSTOMERQUEUE}${id}/status`;

  var config = {
    method: "put",
    url: urls,
    headers: {
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("PUT", urls, data, null, null, history);
};

export const getReminders = async (history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.FETCH_REMINDERS}/${await userId(
    history
  )}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
};

export const reminderAdd = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.ADD_REMINDER}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      // 'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const reminderUpdate = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.ADD_REMINDER}`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      accept: "*/*",
      // 'token': await token(history),
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("PUT", urls, data, null, null, history);
};

export const deleteReminder = async (reminderId, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.DELETE_REMINDERS}/${reminderId}`;

  var config = {
    method: "delete",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("DELETE", urls, null, null, null, history);
  // return await axios(config);
};

export const getSchedules = async (history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.LIST_MEETING}/${await userId(
    history
  )}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getAgentAppointments = async (history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.AGENT_MEETING}/${await userEmail(
    history
  )}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const getAllAppointments = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.GET_ALL_APPOINTMENTS}`;

  var config = {
    method: "post",
    url: urls,
    headers: {
      // 'token': await token(history),
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("POST", urls, data, null, null, history);
};

export const deleteMeeting = async (id, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.DELETE_MEETING}/${id}`;

  var config = {
    method: "delete",
    url: urls,
    headers: {
      // 'token': await token(history),
      // accept: "*/*",
      accept: "application/json",
    },
  };
  return await apiRequest("DELETE", urls, null, null, null, history);
  // return await axios(config);
};

// Delete Facebook Subscription
export const removeMeetingFromCalendar = async (id, history) => {
  var urls = `${AGENT_SERVICE}api/v1/Meeting/Remove/${id}`;
  var config = {
    method: "delete",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("DELETE", urls, null, null, null, history);
  // return await axios(config);
};

export const addSchedule = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.SCHEDULE_MEETING}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      Accept: "text/plain",
      // 'token': await token(history),
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const updateSchedule = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.UPDATE_MEETING}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      // 'token': await token(history),
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const addConferenceSchedule = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.CONFERENCE_SCHEDULE_MEETING}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      // 'token': await token(history),
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const updateConferenceScheduleStatus = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.CONFERENCE_UPDATE_SCHEDULE_STATUS}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      // 'token': await token(history),
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const getCanMsg = async (data, history) => {
  var urls = `${AGENT_SERVICE}${
    URL_Properties.CANNED_RESPONSES
  }/${await getCompany(history)}`;
  return await apiRequest("POST", urls, data, null, null, history);
};

export const deleteCanMsg = async (id, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.CANNED_DELETE}${id}`;
  var config = {
    method: "delete",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
  };
  return await apiRequest("DELETE", urls, null, null, null, history);
  // return await axios(config);
};

export const addNewCanMsg = async (template, pattern, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.CANNED_ADD}`;
  let data = JSON.stringify({
    template: template,
    pattern: pattern,
    companyConfig: { id: await getCompany(history) },
  });
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const listCanMsg = async (history) => {
  var urls = `${AGENT_SERVICE}api/v1/CannedMessages/All/company/${await getCompany(
    history
  )}`;
  let data = JSON.stringify({
    size: 100000,
    pageNumber: 0,
  });
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("POST", urls, data, null, null, history);
};

export const updateCanResponse = async (data, history) => {
  var urls = `${AGENT_SERVICE}${URL_Properties.CANNED_ADD}`;

  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest(
    "POST",
    urls,
    JSON.stringify(data),
    null,
    null,
    history
  );
  // return await axios(config);
};

export const addCustomerbyPhone = async (data, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.SAVE_GUEST_CUSTOMER}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const guestCustomer = async (data, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.SAVE_GUEST_CUSTOMER}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const customerByPhone = async (phone, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.GET_CUSTOMER_PHONE}${phone}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  // return await axios(config);
  return await apiRequest("GET", urls, null, null, null, history);
};

export const customerByEmail = async (checkMail, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.GET_CUSTOMER_EMAIL}${checkMail}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
  // return await axios(config);
};

export const customerByID = async (id, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.GET_CUSTOMER_ID}${id}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
  // return await axios(config);
};

export const getAgents = async (history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/v1/OnlineAgent/company/${await getCompany(
    history
  )}`;

  var config = {
    method: "get",
    url: urls,
    headers: {
      Authorization: `Bearer ${await token(history)}`,
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await apiRequest("GET", urls, null, null, null, history);
  // return await axios(config);
};

export const getAgentQueue = async (data, history) => {
  var urls = `${CHAT_SERVICE}agent/queue/company/${await getCompany(history)}`;
  return await apiRequest("POST", urls, data, null, null, history);
};

export const getAgentConversationById = async (id, history) => {
  var urls = `${CHAT_SERVICE}agent/conversations/${id}`;
  return await apiRequest("GET", urls, null, null, null, history);
};

export const addConversation = async (data, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.SAVE_CHAT}`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("POST", urls, data, null, null, history);
};

export const updateConversation = async (data, history) => {
  var urls = `${CHAT_SERVICE}${URL_Properties.SAVE_CHAT}`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("PUT", urls, data, null, null, history);
};

export const offlineConversation = async (data, history) => {
  var urls = `${CHAT_SERVICE}conversation/offline`;

  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const makeAgentEngaged = async (data, history) => {
  var urls = `${AUTHENTICATION_SERVICE}api/v1/User/${await userId(
    history
  )}/status/${data}`;
  var config = {
    method: "put",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("PUT", urls, data, null, null, history);
};
export const sendDocsMessage = async (smsg, history) => {
  var urls = `${CHAT_SERVICE}conversation/documents/message`;

  let data = smsg;

  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const sendChatMessage = async (smsg, history) => {
  var urls = `${CHAT_SERVICE}conversation/message`;

  let data = JSON.stringify({
    type: smsg.type,
    conversationId: smsg.conversationId,
    messagebody: smsg.messagebody,
    sentBy: "AGENT",
  });

  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

// Starting new chat
export const startAgentChatMessage = async (data, history) => {
  var urls = `${CHAT_SERVICE}agent/conversation/start`;
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const sendAgentChatMessage = async (smsg, history) => {
  var urls = `${CHAT_SERVICE}agent/conversation/message`;

  let data = JSON.stringify({
    type: smsg.type,
    conversationId: smsg.conversationId,
    messagebody: smsg.messagebody,
    sentBy: smsg.sentBy,
  });

  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const sendAgentDocsMessage = async (smsg, history) => {
  var urls = `${CHAT_SERVICE}conversation/agent/documents/message`;

  let data = smsg;

  var config = {
    method: "post",
    url: urls,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: `Bearer ${await token(history)}`,
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const sendChatEvent = async (smsg, history) => {
  var urls = `${CHAT_SERVICE}conversation/event`;

  let data = JSON.stringify({
    conversationId: smsg.conversationId,
    messagebody: smsg.messagebody,
    sentBy: "AGENT",
  });
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const sendAgentChatEvent = async (smsg, history) => {
  var urls = `${CHAT_SERVICE}conversation/event`;

  let data = JSON.stringify({
    conversationId: smsg.conversationId,
    messagebody: smsg.messagebody,
    sentBy: smsg.sentBy,
  });
  return await apiRequest("POST", urls, data, null, null, history);
  // return await axios(config);
};

export const addNewChatToServer = async (data, history) => {
  var urls = `${CHAT_SERVICE}conversation/agent/${await userId(history)}/start`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  // return await axios(config);
  return await apiRequest("POST", urls, data, null, null, history);
};

export const resetUnreadMsg = async (data, history) => {
  var urls = `${CHAT_SERVICE}conversation/${data}/read`;
  // return await axios(config);
  return await apiRequest("PATCH", urls, data, null, null, history);
};

export const resetAgentUnreadMsg = async (agentId, convId, history) => {
  var urls = `${CHAT_SERVICE}agent/${agentId}/conversations/${convId}/read`;
  // return await axios(config);
  return await apiRequest("PUT", urls, null, null, null, history);
};

// test bot intent
export const testBotintent = async (data, history) => {
  var urls = `${CHAT_SERVICE}openai`;
  var config = {
    method: "post",
    url: urls,
    headers: {
      accept: "*/*",
      //'token': await token(history),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await apiRequest("POST", urls, data, null, null, history);
};

// CONVERSATION SUMMARY NOTE FROM AI
export const getSummaryNote = async (data) => {
  var urls = `${CHAT_SERVICE}conversation/summary`;
  return await apiRequest("POST", urls, data);
};
