import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import {
  chatEndPopupReducer,
  remindReducer,
  reminderModalReducer,
} from "./remindReducer";
import { appointmentReducer } from "./appointmentReducer";
import { sidebarReducer } from "./sidebarSelection";
import {
  cannedMsgReducer,
  cannedResponseReducer,
  selectedCannedReducer,
  activeAccordionReducer,
  activeAgentChatInput,
  activeAgentCustomerTab,
} from "./cannedMsgReducer";
import {
  customerReducer,
  isEngagedReducer,
  engagedCustomerReducer,
  webSocketReducer,
  displayChat,
} from "./customerReducer";
import {
  agentQueueReducer,
  agentReducer,
  displayAgentChat,
  engagedAgentReducer,
} from "./agentReducer";
import { documentReducer } from "./documentReducer";
import { teamReducer } from "./teamReducer";
import { departmentReducer } from "./departmentReducer";
import { themeReducer } from "./themeReducer";
import { chatLoadingReducer, chatReducer } from "./chatReducer";
import { configReducer } from "./configReducer";
import { jitsiReducer } from "./jitsiReducer";
import { userReducer } from "./userReducer";
import { conferenceWindowReducers } from "./conferenceWindowReducers";
const rootReducer = combineReducers({
  user: authReducer,
  agentInfo: userReducer,
  reminderList: remindReducer,
  showReminderModal: reminderModalReducer,
  showChatEndPopup: chatEndPopupReducer,
  appointmentList: appointmentReducer,
  activePanel: activeAccordionReducer,
  cannedMsgList: cannedMsgReducer,
  activeAgentChatInput: activeAgentChatInput,
  activeAgentCustomerTab: activeAgentCustomerTab,
  cannedResponse: cannedResponseReducer,
  selectedCannedResponse: selectedCannedReducer,
  isEngaged: isEngagedReducer,
  displayChat: displayChat,
  displayAgentChat: displayAgentChat,
  onlineCustomerList: customerReducer,
  engagedCustomer: engagedCustomerReducer,
  engagedAgent: engagedAgentReducer,
  webSocket: webSocketReducer,
  agentList: agentReducer,
  agentQueueList: agentQueueReducer,
  documentList: documentReducer,
  teamList: teamReducer,
  departmentList: departmentReducer,
  themeList: themeReducer,
  chatList: chatReducer,
  loadingIndicator: chatLoadingReducer,
  configList: configReducer,
  jitsiData: jitsiReducer,
  conferenceData: conferenceWindowReducers,
  sidebarSelection: sidebarReducer,
});

export default rootReducer;
