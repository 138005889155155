import React from "react";
import "./VerifyEmail.scss";
import Header from '../Header'
import { useHistory } from 'react-router-dom'

const VerifyEmail = () => {
  let history = useHistory();
  const content = () => (
    history.location.pathname === "/verify-email" ? 
    "Send verification email. Look for the verification email in your inbox and click the link in that email. A confirmation message will appear in your web browser." :
    history.location.pathname === "/thanks-for-connecting" ?  "Thank you for your interest in Dialogview. Our executive will reach out to you soon for further assistance" : 
    "Please look for the password reset email in your inbox and click on the link"
  )
  return (
    <>
      <Header />
        <div className="verify-email-block">
          <h2>{history.location.pathname === "/thanks-for-connecting" ? "": "Verify your email address"}</h2>
          <h4>{content()}</h4>
      ` </div>
      </>
  );
};

export default VerifyEmail;
