import React, { useEffect, useState } from "react";
import { Button, Tooltip, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./conference-call-window.scss";
import Draggable from "react-draggable";
import {
  FaWindowMinimize,
  FaWindowMaximize,
  FaWindowClose,
} from "react-icons/fa";

import {
  makeAgentEngaged,
  updateConferenceScheduleStatus,
} from "../../actions/conversations";
import JitsiWindow from "./JitsiWindow";
import { Resizable, ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { UTILS } from "../../util/CommonUtilComponent";
const ConferenceWindow = ({ title, children }) => {
  let dispatch = useDispatch();
  const history = useHistory();

  const {
    conferenceData,
    agentInfo,
    engagedCustomer,
  } = useSelector((state) => ({ ...state }));
  const { realTimeCallStarted, RealTimeData } = conferenceData;
  const { conferenceStarted, appointment } = conferenceData;
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth - 200,
    height: window.innerHeight - 100,
  });
  const [position, setPosition] = useState({ x: 100, y: -250 });

  useEffect(() => {
    // Attach the resize event listener
    // window.addEventListener("resize", handleWindowResizeToBottomRight);
    // Clean up the event listener on unmount
    // return () => {
    //   window.removeEventListener("resize", handleWindowResizeToBottomRight);
    // };
  }, []);

  const handleMinimize = () => {
    setIsMinimized(true);
    setPosition({ x: window.innerWidth - 400, y: window.innerHeight - 500 }); // Adjust the values as needed
    setWindowSize({ height: 200, width: 300 });
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setPosition({ x: 100, y: -250 });
    setWindowSize({
      width: window.innerWidth - 200,
      height: window.innerHeight - 100,
    });
  };

  const handleExpand = () => {
    setIsMaximized(!isMaximized);
  };

  const cancelPopupButton = (
    <>
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close("conferenceWarning");
          onLoadJitsiCall(false, "online");
          dispatch({
            type: "CONFERENCE_UPDATE",
            payload: {
              conferenceStarted: false,
            },
          });
          updateScheduleStatus(false, history);
        }}
      >
        Confirm
      </Button>
      &nbsp;
      <Button
        size="small"
        onClick={() => notification.close("conferenceWarning")}
      >
        Cancel
      </Button>
    </>
  );

  const handleClose = () => {
    // if yes update conference status to false and close the window
    // Swal.fire({
    //   title: "You will be removed from the Conference !",
    //   // text: "Do you want to continue",
    //   icon: "question",
    //   confirmButtonText: "ok",
    //   showCancelButton: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     // update agent status to online
    //     onLoadJitsiCall(false, "online");
    //     dispatch({
    //       type: "CONFERENCE_UPDATE",
    //       payload: {
    //         conferenceStarted: false,
    //       },
    //     });
    //     updateScheduleStatus(false, history);
    //   }
    // });

    UTILS.CustomCloseNotification(
      "info",
      "You will be removed from the Conference !",
      cancelPopupButton,
      "conferenceWarning"
    );
  };

  const onLoadJitsiCall = (IS_ENGAGED = false, STATUS = "online") => {
    dispatch({
      type: "IS_ENGAGED",
      payload: IS_ENGAGED,
    });

    // make agent STATUS to engaged
    agentInfo.status = STATUS;
    dispatch({
      type: "ADD_USERDATA",
      payload: agentInfo,
    });

    // adding engaged details to conference state
    dispatch({
      type: "CONFERENCE_UPDATE",
      payload: {
        callEngagedCustomer: STATUS == "online" ? {} : engagedCustomer,
      },
    });

    // make agent status to engaged
    makeAgentEngaged(STATUS,history)
      .then((res) => {
        if(res && res.data && res.data.data){
        console.log(res.data.data);
      }})
      .catch((err) => {
        UTILS.Notification("error", "Error while adding agent to chat");
        console.log(err);
      });
  };

  const handleResize = (event, { element, size }) => {
    // Handle resize action here
    // The 'size' parameter contains the new width and height after resizing
    // You can access size.width and size.height to update your component's state
    // console.log("New size:", size);
    setWindowSize(size);
  };

  const handleWindowResizeToBottomLeft = () => {
    // Adjust the position based on zoom level and window dimensions
    const adjustedX = position.x * (window.innerWidth / window.outerWidth);
    const adjustedY = position.y * (window.innerHeight / window.outerHeight);
    setPosition({ x: adjustedX, y: adjustedY });
  };
  const handleWindowResizeToBottomRight = () => {
    // Calculate adjusted position based on window dimensions and zoom level
    const adjustedX =
      window.innerWidth -
      (window.innerWidth - position.x) *
        (window.innerWidth / window.outerWidth);
    const adjustedY =
      window.innerHeight -
      (window.innerHeight - position.y) *
        (window.innerHeight / window.outerHeight);

    setPosition({ x: adjustedX, y: adjustedY });
  };

  const handleDrag = (e, data) => {
    const { clientX, clientY } = e;

    console.log("e ->", e, "data -> ", data);
    const { offsetWidth, offsetHeight } = data.node;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const maxX = windowWidth - offsetWidth;
    const maxY = windowHeight - offsetHeight;

    const x = Math.min(Math.max(0, clientX - data.x), maxX);
    const y = Math.min(Math.max(0, clientY - data.y), maxY);

    setPosition({
      x: data.x,
      y: data.y,
    }); // Update position while dragging
  };

  const updateScheduleStatus = (callStarted, history) => {
    let body = {
      ...appointment,
      callStarted: callStarted,
    };
    updateConferenceScheduleStatus(body, history)
      .then((res) => {
        console.log("res => ", res);
      })
      .catch((error) => {});
  };

  return (
    <div>
      {conferenceStarted && (
        <Draggable
          onDrag={handleDrag}
          handle=".window-title"
          // defaultPosition={{ x: 10, y: 10 }}
          position={position}
          // grid={[25, 25]}
          scale={1}
          bounds="body"
        >
          <div
            className="resizable-window"
            style={{ height: windowSize.height, width: windowSize.width }}
          >
            <div className="window-title">
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Tooltip title={title} placement="topLeft">
                  {title}
                </Tooltip>
              </div>
              <div className="window-actions">
                <FaWindowMinimize
                  onClick={handleMinimize}
                  className="window-action"
                />
                <FaWindowMaximize
                  onClick={handleMaximize}
                  className="window-action"
                />
                <FaWindowClose
                  onClick={handleClose}
                  className="window-action"
                />
              </div>
            </div>
            {/* <div className="window-content">{children}</div>
            <div className="resize-handle" onMouseDown={handleResize} /> */}

            <div
              className="window-content"
              //   style={{ height: windowSize.height, width: windowSize.width }}
            >
              {/* Window content goes here */}
              <JitsiWindow
                callType={"video"}
                appointment={appointment}
                resize={windowSize}
                closeModalParent={() => {
                  dispatch({
                    type: "CONFERENCE_UPDATE",
                    payload: {
                      conferenceStarted: false,
                    },
                  });
                }}
              />
            </div>

            {/* <Resizable
              width={windowSize.width}
              height={windowSize.height}
              minConstraints={[300, 200]}
              maxConstraints={[window.innerWidth, window.innerHeight]}
              onResize={handleResize}
            >
              <div className="resize-handle" />
            </Resizable> */}
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default ConferenceWindow;
